// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../components/estateBrokerOfficeHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import EstateBrokerOfficeInfo from "../components/estateBrokerOfficeInfo";
import EstateBrokerOfficeNotes from "../components/estateBrokerOfficeNotes";
import EstateBrokerOfficeAddress from "../components/estateBrokerOfficeAddress";
import EstateBrokerOfficeDocuments from "../components/estateBrokerOfficeDocuments";
import EstateBrokerOfficeBankAccountInfo from "../components/estateBrokerOfficeBankAccountInfo";

const GET_ESTATEBROKEROFFICE = gql`
  query GET_ESTATEBROKEROFFICE($id: ID!) {
    realEstateBrokerOffice(id: $id) {
      id
      name
      loginAsUrl
      address {
        roadNameAndNumber
        zipCode
        city
      }
      documents {
        id
        filename
        orgDocStorageUrl
      }
      bankAccountNumber
      bankName
      bankRegistrationNumber
      cvr
      email
      note
      ownerName
      phone
      websiteUrl
      bankRegistrationNumber
      bankAccountNumber
      bankName
      shopNo
      contactName
    }
  }
`;

const SENT_WELCOMEMAIL_ESTATEBROKEROFFICE = gql`
  mutation sentEstateBrokerOfficeWelcomeMail($estateBrokerOfficeId: ID!) {
    sentEstateBrokerOfficeWelcomeMail(estateBrokerOfficeId: $estateBrokerOfficeId)
  }
`;

function Info() {
  // Chakra color mode
  const toast = useToast();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [sentWelcomeMail] = useMutation(SENT_WELCOMEMAIL_ESTATEBROKEROFFICE);
  const { estateBrokerId, estateBrokerOfficeId } = useParams();
  const { loading, error, data, refetch } = useQuery(
    GET_ESTATEBROKEROFFICE,
    {
      variables: { id: estateBrokerOfficeId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  const handleSentWelcomeMailClicked = () => {
    sentWelcomeMail({ variables: { estateBrokerOfficeId: estateBrokerOfficeId } }).then(() => {
      toast({
        title: "Der er nu sendt en velkomst mail til mægleren.",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, der blev IKKE sendt en velkomst mail til mægleren.",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  function forceRerender() {
    refetch();
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.realEstateBrokerOffice.name}
        description={"Butik - Overblik"}
        view={"info"}
        estateBrokerId={estateBrokerId}
        estateBrokerOfficeId={estateBrokerOfficeId}
        onSentWellcomesMessageClicked={handleSentWelcomeMailClicked}
        loginAsUrl={data.realEstateBrokerOffice.loginAsUrl}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap="22px">
        <EstateBrokerOfficeInfo data={data} />
        <GridItem colSpan={1} rowSpan={0}>
          <EstateBrokerOfficeAddress data={data} />
          <br />
          <EstateBrokerOfficeBankAccountInfo data={data} />
        </GridItem>
        
        <EstateBrokerOfficeDocuments data={data.realEstateBrokerOffice} forceRerender={forceRerender} />
        <EstateBrokerOfficeNotes data={data} />
      </Grid>
    </Flex>
  );
}

export default Info;
