// Chakra imports
import {
    Flex, useColorModeValue, Text, Grid, GridItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    Select,
    useToast
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState, useRef } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import LeadHeader from "./components/leadHeader"
import SettleTable from "./../../../components/Tables/SettleTable";
import DataSelectorCardLeads from "./components/DataSelectorCardLeads"
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { SingleDatepicker } from "chakra-dayzed-datepicker"

const LEADS_GQL = gql`
query leads($input: LeadsInput!){
    leads(input: $input){
        id
        status
        customerName
        customerType
        externalOrderId
        fullAddress
        nextProcessingDate
        realEstateBrokerOfficeName
        createdAt
        affiliatePartnerProduct {
            id
            name
            affiliatePartner {
                id
                name
            }
        }
    }
    affiliatePartnerProducts{
        id
        name
    }
    affiliatePartners{
        id
        name
        products{
            id
            name
        }
    }
    realEstateBrokers{
        id
        name
        offices{
            id
            name
        }
    }
}`

const CREATE_LeadGql = gql`
  mutation createLead($input: CreateLeadInput!){
    createLead(input:$input){
        id
    }
  }`

  const UPDATE_LEADS_GQL = gql`
  mutation updateLeadsStatus($leadIds: [ID!], $status: LeadStatusType!){
    updateLeadsStatus(leadIds: $leadIds, status: $status)
  }`


function Leads() {
    const toast = useToast()
    const inpNameRef = useRef(null);
    const inpEmailRef = useRef(null);
    const inpPhoneRef = useRef(null);
    const inpRoadNameAndNumberRef = useRef(null);
    const inpZipCodeAndCityRef = useRef(null);
    const inpRealEstateBrokerRef = useRef(null);
    const inpRealEstateBrokerOfficeRef = useRef(null);
    const inpPartnerRef = useRef(null);
    const inpPartnerProductRef = useRef(null);
    const { isOpen: createLeadIsOpen, onOpen: createLeadOnOpen, onClose: createLeadOnClose } = useDisclosure();
    const { isOpen: changeStatusIsOpen, onOpen: changeStatusOnOpen, onClose: changeStatusOnClose } = useDisclosure();
    const [changeToStatus, setChangeToStatus] = useState('ACCEPTED_PROCESSED');
    const history = useHistory();

    // Chakra color mode
    let today = new Date();
    today.setDate(today.getDate() + 1)
    let defaultfromDate = new Date();
    defaultfromDate.setMonth(today.getMonth() - 1)

    const { realestatebrokerofficeid } = useParams();
    let realestatebrokerofficeids = [];
    if (realestatebrokerofficeid) {
        realestatebrokerofficeids.push(realestatebrokerofficeid);
    }

    const [selecetedRealEstateBrokerId, setSelecetedRealEstateBrokerId] = useState("");
    const [selecetedPartnerId, setSelecetedPartnerId] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
        status: [],
        affiliatePartnerProductIds: [],
        realEstateBrokerIds: [],
        realEstateBrokerOfficeIds: realestatebrokerofficeids
    });

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [createNewLead] = useMutation(
        CREATE_LeadGql,
        {
            refetchQueries: [LEADS_GQL],
            variables: {
                input: {
                    fromDate: requestData.fromDate,
                    toDate: requestData.toDate,
                    status: requestData.status,
                    affiliatePartnerProductIds: requestData.affiliatePartnerProductIds,
                    realEstateBrokerIds: requestData.realEstateBrokerIds,
                    realEstateBrokerOfficeIds: requestData.realEstateBrokerOfficeIds
                }
            }
        }
    )

    const [updateLeads] = useMutation(
        UPDATE_LEADS_GQL,
        {
            refetchQueries: [LEADS_GQL],
            variables: {
                input: {
                    fromDate: requestData.fromDate,
                    toDate: requestData.toDate,
                    status: requestData.status,
                    affiliatePartnerProductIds: requestData.affiliatePartnerProductIds,
                    realEstateBrokerIds: requestData.realEstateBrokerIds,
                    realEstateBrokerOfficeIds: requestData.realEstateBrokerOfficeIds
                }
            }
        }
    )

    const { loading, error, data } = useQuery(LEADS_GQL, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                status: requestData.status,
                affiliatePartnerProductIds: requestData.affiliatePartnerProductIds,
                realEstateBrokerIds: requestData.realEstateBrokerIds,
                realEstateBrokerOfficeIds: requestData.realEstateBrokerOfficeIds
            }
        },
        fetchPolicy: 'network-only',
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <LeadHeader
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Leads"}
                description={'Udsøgning af leads i systemet'}
            />
            <Grid templateColumns={{ sm: "1fr", xl: "repeat(1, 1fr)" }} gap="22px">
                <GridItem w='100%'  >

                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting BillingReportPartners from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting BillingReportPartners from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, selectedAffiliatePartnerProducts, selectedRealEstateBrokers, status) => {

        console.log('updatefn - status:', status)

        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            status: status,
            affiliatePartnerProductIds: selectedAffiliatePartnerProducts,
            realEstateBrokerIds: selectedRealEstateBrokers,
            realEstateBrokerOfficeIds: requestData.realEstateBrokerOfficeIds
            //affiliatePartnerIds:affiliatePartnerIds
        })
    }

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "Kunde navn",
            accessor: "customerName",
            onClick: rowClicked
        },
        {
            header: "Partner",
            accessor: "affiliatePartnerProduct.affiliatePartner.name",
            onClick: rowClicked
        },
        {
            header: "Produkt",
            accessor: "affiliatePartnerProduct.name",
            onClick: rowClicked
        },
        {
            header: "Adresse",
            accessor: "fullAddress",
            onClick: rowClicked
        },
        {
            header: "Oprettet",
            accessor: "createdAt",
            onClick: rowClicked
        },
        {
            header: "Oprettet af",
            accessor: "realEstateBrokerOfficeName",
            onClick: rowClicked
        },
        {
            header: "Status",
            accessor: "status",
            onClick: rowClicked
        }
    ];

    function rowClicked(obj) {
        history.push('/admin/lead/' + obj.id + '/info');
    }

    const createLead = () => {
        let inputData = {}
        inputData.realEstateBrokerId = inpRealEstateBrokerRef.current.value;
        inputData.realEstateBrokerOfficeId = inpRealEstateBrokerOfficeRef.current.value;
        inputData.affiliatePartnerProductId = inpPartnerProductRef.current.value;
        inputData.roadNameAndNumber = inpRoadNameAndNumberRef.current.value;
        inputData.zipCodeAndCity = inpZipCodeAndCityRef.current.value;
        inputData.customerName = inpNameRef.current.value;
        inputData.customerPhone = inpPhoneRef.current.value;
        inputData.customerEmail = inpEmailRef.current.value;
        inputData.takeoverDate = selectedDate;


        createNewLead({
            variables: { input: inputData }
        }).then(() => createLeadOnClose())
            .catch((error => {
                if (error.graphQLErrors[0].message === 'Error validating address') {
                    toast({
                        title: 'Opret lead fejlede.',
                        description: "Adressen er ikke valid.",
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Opret lead fejlede.',
                        description: "Det var ikke muligt at oprette lead´et.",
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    });
                }
            })
            );

        //createLeadOnClose();

    }

    const showCreateLeadDialog = () => {
        createLeadOnOpen();
    }

    const handleSelectionChanged = (selected) => {
        setSelectedLeads(selected)
    }

    const updateChangeStatus = (status) => {
        setChangeToStatus(status);
    }

    const handleChangeStatusClicked = () => {
        //let inputData = {}
        const leadIds = selectedLeads.map((lead) => lead.id);
        const status = changeToStatus;

      
        updateLeads({
            variables: { leadIds: leadIds, status: status }
        }).then(() => {
            changeStatusOnClose();
            //refetch()
        })
        .catch((error => {
            console.log('error:', error)
            toast({
                title: 'Opdatering af status fejlede.',
                description: "Det var ikke muligt at ændre status.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });

        })
        );
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.leads));

    for (var i = 0; i < tableData.length; i++) {

        tableData[i].createdAt = tableData[i].createdAt.split('T')[0];

        switch (tableData[i].status) {
            case 'ACCEPTED':
                tableData[i].status = 'Oprettet'
                break;
            case 'DECLINED_PROCESSED':
                tableData[i].status = 'Gennemført - Afvist'
                break;
            case 'ACCEPTED_PROCESSED':
                tableData[i].status = 'Gennemført - Accepteret'
                break;
            case 'ACCEPTED_SENT_TO_PARTNER':
                tableData[i].status = 'Sendt til partner'
                break;
            case 'ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL':
                tableData[i].status = 'Sendt til partner, afventer fortrydelses ret'
                break;
            case 'ACCEPTED_SENT_TO_PARTNER_ERROR':
                tableData[i].status = 'Fejlet'
                break;
            case 'ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL_ERROR':
                tableData[i].status = 'Fejlet'
                break;
            case 'SENT_TO_PARTNER_WAITING_FOR_FEEDBACK':
                tableData[i].status = 'Sendt til partner, afventer tilbage melding'
                break;
            case 'SENT_TO_PARTNER_WAITING_FOR_FEEDBACK_NO_RESPONSE_HANDLE_MANUALY':
                tableData[i].status = 'Fejlet, partner har ikke vendt tilbage på lead´et'
                break;
            default:
                break;
        }
    }

    //set realestate broker offices
    let realEstateBrokerOffices = [];
    for (var r = 0; r < data.realEstateBrokers.length; r++) {
        if (data.realEstateBrokers[r].id === selecetedRealEstateBrokerId) {
            realEstateBrokerOffices = data.realEstateBrokers[r].offices;
        }
    }
    if (selecetedRealEstateBrokerId === "") {
        realEstateBrokerOffices = data.realEstateBrokers[0].offices;
    }

    //set partner products
    let affiliatePartnerProducts = [];
    for (var ap = 0; ap < data.affiliatePartners.length; ap++) {
        if (data.affiliatePartners[ap].id === selecetedPartnerId) {
            affiliatePartnerProducts = data.affiliatePartners[ap].products;
        }
    }
    if (selecetedPartnerId === "") {
        affiliatePartnerProducts = data.affiliatePartners[0].products;
    }

    console.log('requestData:', requestData)

    let customButtonText1 = 'Ændre status';
    if (selectedLeads.length === 0) {
        customButtonText1 = '';
    }

    return (
        <div>
            <Flex direction="column">
                <LeadHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Leads"}
                    description={'Udsøgning af leads i systemet'}
                    showCreateLeadDialog={showCreateLeadDialog}
                />
                <Grid templateColumns={{ sm: "1fr", xl: "1fr" }} gap="22px">
                    <GridItem w='100%'  >
                        <DataSelectorCardLeads
                            updatefn={updatefn}
                            fromDate={requestData.fromDate}
                            toDate={requestData.toDate}
                            status={requestData.status}
                            affiliatePartnerProductIds={requestData.affiliatePartnerProductIds}
                            realEstateBrokerIds={requestData.realEstateBrokerIds}
                            affiliatePartnerProducts={data.affiliatePartnerProducts}
                            realEstateBrokers={data.realEstateBrokers}
                            realEstateBrokerOfficeIds={requestData.realEstateBrokerOfficeIds}
                        />
                    </GridItem>
                </Grid>
                <br />
                <Card px="0px">
                    <CardBody>
                        <SettleTable
                            data={tableData}
                            columnsData={columns}
                            enableSelect={true}
                            onSelectedChanged={handleSelectionChanged}
                            customButtonText1={customButtonText1}
                            customButtonAction1={changeStatusOnOpen}
                        />
                    </CardBody>
                </Card>
            </Flex>


            <Modal onClose={createLeadOnClose} size="xl" isOpen={createLeadIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret nyt lead</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprettet et nyt lead, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Kunde:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Navn:{" "}
                                </Text>
                                <Input
                                    ref={inpNameRef}
                                    variant='flushed'
                                    type='text'
                                    name="name"

                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    E-mail:{" "}
                                </Text>
                                <Input
                                    ref={inpEmailRef}
                                    variant='flushed'
                                    type='text'
                                    name="email"
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Telefon nr.:{" "}
                                </Text>
                                <Input
                                    ref={inpPhoneRef}
                                    variant='flushed'
                                    type='text'
                                    name="phone"
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Vejnavn og nr.:{" "}
                                </Text>
                                <Input
                                    ref={inpRoadNameAndNumberRef}
                                    variant='flushed'
                                    type='text'
                                    name="roadNameAndNumber"
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Post nr. og by:{" "}
                                </Text>
                                <Input
                                    ref={inpZipCodeAndCityRef}
                                    variant='flushed'
                                    type='text'
                                    name="zipCodeAndCity"
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Overtagelse dato:{" "}
                                </Text>
                                <SingleDatepicker

                                    date={selectedDate}
                                    onDateChange={(e) => { setSelectedDate(e) }}

                                    configs={{
                                        dateFormat: 'yyyy-MM-dd',
                                    }}
                                    propsConfigs={{
                                        dayOfMonthBtnProps: {
                                            defaultBtnProps: {
                                                borderColor: "yellow.300",
                                                _hover: {
                                                    background: 'yellow.400',
                                                }
                                            },
                                            isInRangeBtnProps: {
                                                background: "yellow.300",
                                                color: "black",
                                            },
                                            selectedBtnProps: {
                                                background: "yellow.300",
                                                color: "black",
                                            }
                                        }
                                    }}
                                />

                            </Flex>
                        </Flex>
                        <Text fontSize='md' fontWeight='bold'>Ejendomsmægler:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Kæde:{" "}
                                </Text>
                                <Select ref={inpRealEstateBrokerRef} multiple={false} onChange={(e) => { setSelecetedRealEstateBrokerId(e.target.value) }}>
                                    {data.realEstateBrokers.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Butik:{" "}
                                </Text>
                                <Select ref={inpRealEstateBrokerOfficeRef} multiple={false} onChange={() => { }}>
                                    {realEstateBrokerOffices.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        <Text fontSize='md' fontWeight='bold'>Produkt:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Partner:{" "}
                                </Text>
                                <Select ref={inpPartnerRef} multiple={false} onChange={(e) => { setSelecetedPartnerId(e.target.value) }}>
                                    {data.affiliatePartners.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Product:{" "}
                                </Text>
                                <Select ref={inpPartnerProductRef} multiple={false} onChange={() => { }}>
                                    {affiliatePartnerProducts.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createLeadOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createLead} ml={3}>Opret</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal onClose={changeStatusOnClose} size="xl" isOpen={changeStatusIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ændre status</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at ændre status på et eller flere leads, udfyld nedenstående og klik ændre.<br />* Der udsendes ikke beskeder.</Text>
                        <br /><br />
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Status:{" "}
                                </Text>
                                <Select
                                    multiple={false}
                                    value={data.status}
                                    onChange={(e) => {
                                        updateChangeStatus(
                                            e.target.value
                                        );
                                    }}
                                >
                                    <option key={"ACCEPTED_PROCESSED"} value="ACCEPTED_PROCESSED">
                                        Godkendt
                                    </option>
                                    <option key={"DECLINED_PROCESSED"} value="DECLINED_PROCESSED">
                                        Afvist
                                    </option>
                                </Select>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={changeStatusOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={handleChangeStatusClicked} ml={3}>Ændre</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}

export default Leads;
