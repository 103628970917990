import './productBox.scss';
import React from 'react';
import Typography from '../../../../components/atoms/typography/typography';
const ProductBox = (props) => {
    //const [productSelected, setProductSelected] = useState<boolean>(props.selected)
    const handleProductClicked = () => {
        props.onProductClicked(props.id);
    };
    return (React.createElement("div", { className: props.ordered ? 'ProductBoxContainerOrdered' : 'ProductBoxContainer', onClick: () => handleProductClicked() },
        React.createElement("div", { className: 'ProductBoxLeftContainer' },
            props.ordered ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'ProductBoxOrderedContainer' },
                    React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                        React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color-checkmark-disabled' }))))) : (React.createElement(React.Fragment, null, !props.disabled ? (React.createElement("div", { className: 'ProductBoxCheckBoxContainer' },
                React.createElement(React.Fragment, null, props.selected ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                    React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                    React.createElement("g", { id: 'Rectangle_1750', "data-name": 'Rectangle 1750', fill: '#fff', stroke: '#c4c5cb', strokeWidth: '1' },
                        React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                        React.createElement("rect", { x: '0.5', y: '0.5', width: '23', height: '23', rx: '1.5', fill: 'none' }))))))) : (React.createElement("div", { style: { width: '10px' } })))),
            React.createElement("div", { className: 'ProductBoxProductNameContainer' },
                React.createElement(Typography, { tag: 'p', style: { fontWeight: 'bold' }, tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: props.name } }))),
        React.createElement("div", { className: 'ProductBoxProductLogoContainer' }, props.partnerLogo && React.createElement("img", { src: props.partnerLogo.mediaUrl, className: 'ProductBoxProductLogo' }))));
};
export default ProductBox;
