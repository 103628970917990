// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast, Input, Text, Select } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import Header from "../components/movingPlannerCategoryItemHeader";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PLANNER_ITEM_GQL, UPDATE_ITEM_GQL } from "./graphQL"
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import Editor from "./../../../../components/Editor/Editor"
import MovingplannerItemLinks from "./../components/movingplannerItemLinks";
import MovingplannerProductCategories from "./../components/movingplannerProductCategories";
import MovingPlannerItemMediaUpload from "./MovingPlannerItemMediaUpload"
import MovingPlannerItemConditions from "../components/MovingPlannerItemConditions"
import MovingPlannerItemIconUpload from "./MovingPlannerItemIconUpload"

function MovingPlannerItemEdit() {
  // Chakra color mode
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { categoryId, itemId } = useParams();
  const [updatePlannerItem,] = useMutation(UPDATE_ITEM_GQL, { refetchQueries: [GET_PLANNER_ITEM_GQL] });
  const { loading, error, data, refetch } = useQuery(GET_PLANNER_ITEM_GQL, {
    variables: { id: itemId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting planner item from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting planner item from server (2)</p>);
  }

  const updateData = (id, field, data) => {
    let inputData = JSON.parse("{\"" + field + "\":\"\"}");

    inputData[field] = data;

    inputData.id = id;
    updatePlannerItem({ variables: { input: inputData } })

    toast({
      title: 'Ændringen er blevet gemt.',
      description: "",
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }

  var timeout = null;
  const updateText = (text) => {
    clearTimeout(timeout);

    if (text.trim() !== "") {
      timeout = setTimeout(function () {
        updateData(itemId, 'text', text)
      }, 2000);
    }
  }

  function forceRerender() {
    refetch()
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.heading}
        description={"Redigering af punktet: " + data.plannerItem.heading}
        view={"info"}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} gap="22px">
        <GridItem w='100%' colSpan={0} rowSpan={0} >
          <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p='12px 5px' mb='12px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Punkt
              </Text>
            </CardHeader>
            <CardBody px='5px'>
              <Flex direction='column' style={{ width: '100%' }}>
                <Flex align='center' mb='18px'>
                  <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'160px'}>
                    Type:{" "}
                  </Text>
                  <Select
                    onChange={(e) => updateData(data.plannerItem.id, 'itemType', e.target.value)}
                    cursor="pointer"
                    defaultValue={data.plannerItem.itemType}
                  >
                    <option value={"NORMAL"}>Normal</option>
                    <option value={"HANDOVER_PERSONS"}>Nøgleoverdragelse - Kontakt oplysninger</option>
                    <option value={"HANDOVER_ELECTRICITY"}>Nøgleoverdragelse - Strømmåler</option>
                    <option value={"HANDOVER_WATER"}>Nøgleoverdragelse - Vandmåler</option>
                    <option value={"HANDOVER_WASTEWATER"}>Nøgleoverdragelse - Spildevandsmåler</option>
                    <option value={"HANDOVER_HEAT"}>Nøgleoverdragelse - Varmemåler</option>
                    <option value={"HANDOVER_SESSION"}>Nøgleoverdragelse - Huske liste</option>
                    <option value={"HANDOVER_SIGNING"}>Nøgleoverdragelse - Signering</option>
                  </Select>
                </Flex>

                <Flex align='center' mb='18px'>
                  <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'160px'}>
                    Overskrift:{" "}
                  </Text>
                  <Input
                    variant='flushed'
                    type='text'
                    name="heading"
                    defaultValue={data.plannerItem.heading}
                    onBlur={e => (updateData(data.plannerItem.id, 'heading', e.target.value))}
                  />
                </Flex>
                <Flex align='center' mb='18px'>
                  <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'160px'}>
                    Underoverskrift:{" "}
                  </Text>
                  <Input
                    variant='flushed'
                    type='text'
                    name="subHeading"
                    defaultValue={data.plannerItem.subHeading}
                    onBlur={e => (updateData(data.plannerItem.id, 'subHeading', e.target.value))}
                  />
                </Flex>
                {data.plannerItem.itemType === "NORMAL" && (

                <Flex align='center' mb='18px'>
                  <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'160px'}>
                    Tekst:{" "}
                  </Text>
                 
                  <Editor value={data.plannerItem.text} changed={(e) => (updateText(e))} />
                 
                </Flex>
                 )}
              </Flex>
            </CardBody>
          </Card>

          <MovingPlannerItemIconUpload data={data.plannerItem} forceRerender={forceRerender} />



          {data.plannerItem.itemType === "NORMAL" && (
            <>
              <MovingplannerItemLinks plannerCategoryId={categoryId} plannerItemId={itemId} />
              <MovingPlannerItemMediaUpload data={data.plannerItem} forceRerender={forceRerender} />
              <MovingPlannerItemConditions conditionFields={data.conditionFields} conditions={data.plannerItem.conditions} forceRerender={forceRerender} type={'PlannerItem'} refId={data.plannerItem.id} />
              <MovingplannerProductCategories plannerCategoryId={categoryId} plannerItemId={itemId} />
            </>
          )}
        </GridItem>
      </Grid>
    </Flex>
  );
}


export default MovingPlannerItemEdit;
