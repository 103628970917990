/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure
} from "@chakra-ui/react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import CardHeader from "./../../../components/Card/CardHeader";

import SearchTable1 from "./../../../components/Tables/SearchTable1";
import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";


const GET_ESTATEBROKERS = gql`
query GET_ESTATEBROKERS{
  realEstateBrokers{
    id
    active
    address{
        fullAddress
    }
    cvr
    email
    name
  }
  }`

const CREATE_RealEstateBrokerGql = gql`
  mutation createRealEstateBroker($input: CreateRealEstateBrokerInput!) {
    createRealEstateBroker(input: $input) {
      id
    }
  }
`;

const columns = [
  {
    Header: "",
    accessor: "id",
  },
  {
    Header: "NAVN",
    accessor: "name",
  },
  {
    Header: "ADDRESSE",
    accessor: "address.fullAddress",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CVR",
    accessor: "cvr",
  }
];

function EstateBrokers() {
  const textColor = useColorModeValue("gray.700", "white");
  const cancelRefCreateDialog = React.useRef();
  const newNameRef = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();
  const [createEstateBroker] = useMutation(CREATE_RealEstateBrokerGql, { refetchQueries: [GET_ESTATEBROKERS] });

  const { loading, error, data } = useQuery(GET_ESTATEBROKERS, {
    variables: {},
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting stats from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting stats from server (2)</p>);
  }

  const showCreateModal = () => {
    onOpenCreateDialog();
  }

  const createEstateChain = () => {
    let inputData = {};
    inputData.name = newNameRef.current.value;

    createEstateBroker({
      variables: { input: inputData },
    });

    onCloseCreateDialog();
  };


  return (
    <>
      <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="24px" justifyContent="space-between">
            <Flex direction="column">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                Ejendomsmæglere
            </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Liste over ejendomsmægler kæder.
            </Text>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="6px"
                cursor="pointer"
                onClick={() => {
                  showCreateModal();
                }}
              >
                Opret ny mæglerkæde
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <SearchTable1 tableData={data.realEstateBrokers} columnsData={columns} />
          </CardBody>
        </Card>
      </Flex>
      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret ny ejendomsmæglerkæde
        </AlertDialogHeader>

            <AlertDialogBody>
              Du er ved at oprette en ny ejendomsmæglerkæde i systemet.
          <br />
              <br />
          Angiv navn:
          <Input ref={newNameRef} type="text" name="name" defaultValue="" />
              <br />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
          </Button>
              <Button colorScheme="green" onClick={createEstateChain} ml={3}>
                Opret
          </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default EstateBrokers;
