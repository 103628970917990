import { __rest } from "tslib";
/**
 * @function DawaAutocomplete2
 */
import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.css';
import * as DawaAutocomplete from 'dawa-autocomplete2';
function DawaAutocomplete2(props) {
    const { classes, inputValue } = props, rest = __rest(props, ["classes", "inputValue"]);
    const containerEl = React.createRef();
    const inputEl = React.createRef();
    const mutationCallback = (mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                if (mutation.target.classList.contains('dawa-selected')) {
                    mutation.target.classList.remove('dawa-selected');
                    mutation.target.classList.add(styles['dawa-selected']);
                    if (classes && classes.selected) {
                        mutation.target.classList.add(classes.selected);
                    }
                }
                if (classes &&
                    classes.ul &&
                    mutation.target.classList.contains('dawa-autocomplete-suggestions') &&
                    !mutation.target.classList.contains(classes.ul)) {
                    mutation.target.classList.add(classes.ul);
                }
                if (classes &&
                    classes.li &&
                    mutation.target.classList.contains('dawa-autocomplete-suggestion') &&
                    !mutation.target.classList.contains(classes.li)) {
                    mutation.target.classList.add(classes.li);
                }
            }
        }
    };
    React.useEffect(() => {
        new MutationObserver(mutationCallback).observe(containerEl.current, {
            attributes: true,
            childList: true,
            subtree: true,
        });
        //const dawaAutocomplete2 = require('dawa-autocomplete2')
        const component = DawaAutocomplete.dawaAutocomplete(inputEl.current, Object.assign({}, rest));
        return () => {
            component.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: classNames(styles['autocomplete-container'], classes && classes.root), ref: containerEl },
        React.createElement("input", { autoComplete: 'off', role: 'presentation', disabled: props.disabled, className: classNames(classes && classes.input), type: 'text', defaultValue: inputValue, ref: inputEl, onBlur: (e) => {
                props.blur(e.target.value);
            } })));
}
export default DawaAutocomplete2;
