import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      wasteWater {
        meterNo
        meterValue
        readingDate
        imageUrl
      }
    }
  }
`;
const UPDATE_WASTEWATER_INFO_GQL = gql `
  mutation updateWasteWater($input: UpdateWasteWaterInput!) {
    updateWasteWater(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_WASTEWATER_INFO_GQL };
