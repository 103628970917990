import React, { useEffect, useState } from 'react';
import './handoverHeat.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Button from '../../../../components/atoms/button/button';
import DatePicker from '../../../../components/molecules/date-picker/date-picker';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HANDOVER_GQL, UPDATE_HEAT_INFO_GQL } from './graphQL';
import UploadImageSmall from './../uploadImageSmall/uploadImageSmall';
var HeatMeterType;
(function (HeatMeterType) {
    HeatMeterType["gas"] = "gas";
    HeatMeterType["olie"] = "olie";
    HeatMeterType["centralHeating"] = "centralHeating";
    HeatMeterType["pillFire"] = "pillFire";
})(HeatMeterType || (HeatMeterType = {}));
const HandoverHeat = ({ handoverId, onNext }) => {
    const { loading, error, data } = useQuery(GET_HANDOVER_GQL, {
        variables: { id: handoverId },
    });
    const [updateWaterInfo, { data: updateData, error: updateError }] = useMutation(UPDATE_HEAT_INFO_GQL, {
        refetchQueries: [{ query: GET_HANDOVER_GQL, variables: { id: handoverId } }],
    });
    const [validate, setValidate] = useState(false);
    const [componentData, setComponentData] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        if (!loading && !error && data) {
            setComponentData({
                meterNo: data.getHandover.heat.meterNo,
                meterType: data.getHandover.heat.meterType,
                gasMeterValue: data.getHandover.heat.gasMeterValue,
                olieMeterValue: data.getHandover.heat.olieMeterValue,
                pillFireMeterValue: data.getHandover.heat.pillFireMeterValue,
                centralHeatingM3MeterValue: data.getHandover.heat.centralHeatingM3MeterValue,
                centralHeatingMWHMeterValue: data.getHandover.heat.centralHeatingMWHMeterValue,
                centralHeatingMKWHMeterValue: data.getHandover.heat.centralHeatingMKWHMeterValue,
                readingDate: data.getHandover.heat.readingDate ? new Date(data.getHandover.heat.readingDate) : new Date(),
                supplier: data.getHandover.heat.supplier,
                woodTakeOver: data.getHandover.heat.woodTakeOver,
                woodTakeOverPrice: data.getHandover.heat.woodTakeOverPrice,
                notes: data.getHandover.heat.notes,
                image1Url: data.getHandover.heat.image1Url,
                image2Url: data.getHandover.heat.image2Url,
                image3Url: data.getHandover.heat.image3Url,
                status: data.getHandover.status,
            });
        }
    }, [loading, error, data]);
    if (loading) {
        return React.createElement("p", null);
    }
    if (error) {
        console.log('get data Error:' + error.message);
        return React.createElement("p", null);
    }
    if (componentData == null) {
        return React.createElement("p", null);
    }
    if (updateError) {
        console.log('update Error:' + updateError.message);
    }
    if (updateData) {
        onNext();
    }
    const handleNextClicked = () => {
        setValidate(true);
        let isvalid;
        isvalid = true;
        for (const key of Object.keys(componentData)) {
            const fieldValue = componentData[key];
            if (fieldValue === '' &&
                key !== 'notes' &&
                key !== 'image1MimeType' &&
                key !== 'image1Filename' &&
                key !== 'image1Url' &&
                key !== 'image1Base64' &&
                key !== 'supplier' &&
                key !== 'image2MimeType' &&
                key !== 'image2Filename' &&
                key !== 'image2Url' &&
                key !== 'image2Base64' &&
                key !== 'image3MimeType' &&
                key !== 'image3Filename' &&
                key !== 'image3Url' &&
                key !== 'image3Base64') {
                isvalid = false;
                console.log('Validate - Invalid field:', key);
            }
        }
        if (componentData.meterType === HeatMeterType.centralHeating) {
            if (componentData.centralHeatingM3MeterValue === 0 ||
                componentData.centralHeatingMKWHMeterValue === 0 ||
                componentData.centralHeatingMWHMeterValue === 0) {
                isvalid = false;
                console.log('Validate - Invalid centralHeating fields');
            }
        }
        if (componentData.meterType === HeatMeterType.gas) {
            if (componentData.gasMeterValue === 0) {
                isvalid = false;
                console.log('Validate - Invalid gas fields');
            }
        }
        if (componentData.meterType === HeatMeterType.olie) {
            if (componentData.olieMeterValue === 0) {
                isvalid = false;
                console.log('Validate - Invalid olie fields');
            }
        }
        if (componentData.meterType === HeatMeterType.pillFire) {
            if (componentData.pillFireMeterValue === 0) {
                isvalid = false;
                console.log('Validate - Invalid pillFire fields');
            }
        }
        if ((!componentData.image1Base64 || componentData.image1Base64 === '') && componentData.image1Url === '') {
            isvalid = false;
            console.log('Validate - No image');
        }
        if (componentData.meterType === HeatMeterType.centralHeating) {
            if ((!componentData.image2Base64 || componentData.image2Base64 === '') && componentData.image2Url === '') {
                isvalid = false;
                console.log('Validate - No image');
            }
            if ((!componentData.image3Base64 || componentData.image3Base64 === '') && componentData.image3Url === '') {
                isvalid = false;
                console.log('Validate - No image');
            }
        }
        if (isvalid) {
            const updateObj = {
                handoverId: handoverId,
                meterNo: componentData.meterNo,
                meterType: componentData.meterType,
                gasMeterValue: parseInt(componentData.gasMeterValue + ''),
                olieMeterValue: parseInt(componentData.olieMeterValue + ''),
                pillFireMeterValue: parseInt(componentData.pillFireMeterValue + ''),
                centralHeatingM3MeterValue: parseInt(componentData.centralHeatingM3MeterValue + ''),
                centralHeatingMWHMeterValue: parseInt(componentData.centralHeatingMWHMeterValue + ''),
                centralHeatingMKWHMeterValue: parseInt(componentData.centralHeatingMKWHMeterValue + ''),
                readingDate: componentData.readingDate,
                supplier: componentData.supplier,
                woodTakeOver: componentData.woodTakeOver,
                woodTakeOverPrice: componentData.woodTakeOverPrice,
                notes: componentData.notes,
            };
            if (componentData.image1Base64 !== '') {
                updateObj.image1Base64 = componentData.image1Base64;
                updateObj.image1mimeType = componentData.image1MimeType;
                updateObj.image1filename = componentData.image1Filename;
            }
            if (componentData.image2Base64 !== '') {
                updateObj.image2Base64 = componentData.image2Base64;
                updateObj.image2mimeType = componentData.image2MimeType;
                updateObj.image2filename = componentData.image2Filename;
            }
            if (componentData.image3Base64 !== '') {
                updateObj.image3Base64 = componentData.image3Base64;
                updateObj.image3mimeType = componentData.image3MimeType;
                updateObj.image3filename = componentData.image3Filename;
            }
            setValidate(false);
            updateWaterInfo({
                variables: {
                    input: updateObj,
                },
            });
        }
        else {
            console.log('Validate - Not all fields are valid');
            forceUpdate();
        }
    };
    const imageChanged = (imgBase64, imgFileName, imgMineType) => {
        const tmp = componentData;
        tmp['image1Base64'] = imgBase64;
        tmp['image1MimeType'] = imgMineType;
        tmp['image1Filename'] = imgFileName;
        tmp['image1Url'] = '';
        setComponentData(Object.assign({}, tmp));
    };
    const image2Changed = (imgBase64, imgFileName, imgMineType) => {
        const tmp = componentData;
        tmp['image2Base64'] = imgBase64;
        tmp['image2MimeType'] = imgMineType;
        tmp['image2Filename'] = imgFileName;
        tmp['image2Url'] = '';
        setComponentData(Object.assign({}, tmp));
    };
    const image3Changed = (imgBase64, imgFileName, imgMineType) => {
        const tmp = componentData;
        tmp['image3Base64'] = imgBase64;
        tmp['image3MimeType'] = imgMineType;
        tmp['image3Filename'] = imgFileName;
        tmp['image3Url'] = '';
        setComponentData(Object.assign({}, tmp));
    };
    const updateField = (fieldName, fieldValue) => {
        const tmp = componentData;
        if (fieldName === 'woodTakeOver') {
            if (fieldValue === 'true') {
                tmp[fieldName] = true;
            }
            else {
                tmp[fieldName] = false;
            }
        }
        else {
            if (fieldName === 'woodTakeOverPrice') {
                tmp[fieldName] = parseInt(fieldValue);
            }
            else {
                tmp[fieldName] = fieldValue;
            }
        }
        setComponentData(Object.assign({}, tmp));
    };
    const handleDaySelect = (fieldName, date) => {
        const tmp = componentData;
        if (date) {
            tmp[fieldName] = date;
            setComponentData(Object.assign({}, tmp));
        }
    };
    const takeoverDateValidateError = false;
    const imageValidateError = validate && (!componentData.image1Base64 || componentData.image1Base64 === '') && componentData.image1Url === ''
        ? true
        : false;
    const image2ValidateError = validate && (!componentData.image2Base64 || componentData.image2Base64 === '') && componentData.image2Url === ''
        ? true
        : false;
    const image3ValidateError = validate && (!componentData.image3Base64 || componentData.image3Base64 === '') && componentData.image3Url === ''
        ? true
        : false;
    const isLocked = componentData.status === 'DONE';
    return (React.createElement(Container, { className: 'handoverHeat', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
        React.createElement(React.Fragment, null,
            React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Indtast m\u00E5lernummer:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.meterNo, className: validate && componentData.meterNo == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('meterNo', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Varmekilde:"),
                            React.createElement("select", { disabled: isLocked, className: 'select', defaultValue: componentData.meterType, onChange: (e) => {
                                    updateField('meterType', e.currentTarget.value);
                                } },
                                React.createElement("option", { key: 'heatingType1', value: 'centralHeating' }, "Fjernvarme"),
                                React.createElement("option", { key: 'heatingType2', value: 'pillFire' }, "Pillefyr"),
                                React.createElement("option", { key: 'heatingType3', value: 'gas' }, "Gas"),
                                React.createElement("option", { key: 'heatingType4', value: 'olie' }, "Olie"))),
                        componentData.meterType === HeatMeterType.centralHeating && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Afl\u00E6st til:"),
                                React.createElement("label", { className: 'inputLabel' },
                                    React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.centralHeatingM3MeterValue, className: validate && componentData.centralHeatingM3MeterValue == 0 ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('centralHeatingM3MeterValue', e.target.value) }),
                                    React.createElement("div", { className: 'inputUnit' },
                                        "m",
                                        React.createElement("sup", null, "3")))),
                            React.createElement("div", { className: 'field' },
                                React.createElement("label", { className: 'inputLabel' },
                                    React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.centralHeatingMWHMeterValue, className: validate && componentData.centralHeatingMWHMeterValue == 0
                                            ? 'fieldinput-error'
                                            : 'fieldinput', onChange: (e) => updateField('centralHeatingMWHMeterValue', e.target.value) }),
                                    ' ',
                                    React.createElement("div", { className: 'inputUnit' }, "mWh"))),
                            React.createElement("div", { className: 'field' },
                                React.createElement("label", { className: 'inputLabel' },
                                    React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.centralHeatingMKWHMeterValue, className: validate && componentData.centralHeatingMKWHMeterValue == 0
                                            ? 'fieldinput-error'
                                            : 'fieldinput', onChange: (e) => updateField('centralHeatingMKWHMeterValue', e.target.value) }),
                                    React.createElement("div", { className: 'inputUnit' }, "kWh"))))),
                        componentData.meterType === HeatMeterType.gas && (React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Afl\u00E6st til:"),
                            React.createElement("label", { className: 'inputLabel' },
                                React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.gasMeterValue, className: validate && componentData.gasMeterValue == 0 ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('gasMeterValue', e.target.value) }),
                                React.createElement("div", { className: 'inputUnit' },
                                    "m",
                                    React.createElement("sup", null, "3"))))),
                        componentData.meterType === HeatMeterType.olie && (React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Afl\u00E6st til:"),
                            React.createElement("label", { className: 'inputLabel' },
                                React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.olieMeterValue, className: validate && componentData.olieMeterValue == 0 ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('olieMeterValue', e.target.value) }),
                                React.createElement("div", { className: 'inputUnit' }, "Liter")))),
                        componentData.meterType === HeatMeterType.pillFire && (React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Afl\u00E6st til:"),
                            React.createElement("label", { className: 'inputLabel' },
                                React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.pillFireMeterValue, className: validate && componentData.pillFireMeterValue == 0 ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('pillFireMeterValue', e.target.value) }),
                                React.createElement("div", { className: 'inputUnit' }, "Liter")))),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Dato for afl\u00E6sning:"),
                            React.createElement(DatePicker, { disabled: isLocked, selected: componentData.readingDate, fieldName: 'readingDate', onSelect: handleDaySelect, error: takeoverDateValidateError })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Bem\u00E6rkninger:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.notes, className: 'fieldinput', onChange: (e) => updateField('notes', e.target.value) })),
                        componentData.meterType !== HeatMeterType.centralHeating &&
                            componentData.meterType !== HeatMeterType.gas && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Tilstedev\u00E6rende br\u00E6nde/br\u00E6ndsel overtages af k\u00F8ber:"),
                                React.createElement("div", { className: 'radioHorizontalMeterUnit' },
                                    React.createElement("input", { disabled: isLocked, type: 'radio', name: 'woodTakeOver', value: 'true', className: 'squareRadio', onClick: () => updateField('woodTakeOver', 'true'), defaultChecked: componentData.woodTakeOver }),
                                    React.createElement("div", { className: 'RightRadioText' }, "Ja")),
                                React.createElement("div", { className: 'radioHorizontalMeterUnit' },
                                    React.createElement("input", { disabled: isLocked, type: 'radio', name: 'woodTakeOver', value: 'false', onClick: () => updateField('woodTakeOver', 'false'), defaultChecked: !componentData.woodTakeOver }),
                                    React.createElement("div", { className: 'RightRadioText' }, "Nej"))),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Hvis ja, Overtages til kr.:"),
                                React.createElement("input", { disabled: isLocked, type: 'number', defaultValue: componentData.woodTakeOverPrice, className: 'fieldinput', onChange: (e) => updateField('woodTakeOverPrice', e.target.value) })))),
                        componentData.meterType === HeatMeterType.centralHeating ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'field' },
                                React.createElement(UploadImageSmall, { disabled: isLocked, uploadTypeName: ' (m<sup>3</sup>)', imageChanged: imageChanged, imageUrl: componentData.image1Url, validateError: imageValidateError })),
                            React.createElement("div", { className: 'field' },
                                React.createElement(UploadImageSmall, { disabled: isLocked, uploadTypeName: ' (mWh)', imageChanged: image2Changed, imageUrl: componentData.image2Url, validateError: image2ValidateError })),
                            React.createElement("div", { className: 'field' },
                                React.createElement(UploadImageSmall, { disabled: isLocked, uploadTypeName: ' (kWh)', imageChanged: image3Changed, imageUrl: componentData.image3Url, validateError: image3ValidateError })))) : (React.createElement(UploadImageSmall, { disabled: isLocked, uploadTypeName: '', imageChanged: imageChanged, imageUrl: componentData.image1Url, validateError: imageValidateError }))))),
            React.createElement(Row, null,
                React.createElement(Column, { width: { tablet: 8 }, style: { paddingRight: '38px' }, className: 'linksContainer' },
                    React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                        React.createElement(Button, { text: 'Gem', width: 180, grey: true, onClick: handleNextClicked })))))));
};
export default HandoverHeat;
/*
              <div className='field'>
                <div className='fieldHeading'>Leverandør:</div>
                <input
                  disabled={isLocked}
                  type='text'
                  defaultValue={componentData!.supplier}
                  className={'fieldinput'}
                  onChange={(e) => updateField('supplier', (e.target as HTMLInputElement).value)}
                />
              </div>
*/
