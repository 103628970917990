// Chakra imports
import { Flex, Text, useColorModeValue, Input } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";

const ArosForsikringIntegrationInfo = ({
  data,
  dataUpdate
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }} h='100%'>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          Aros Forsikring - API integration
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' style={{ width: '100%' }}>
          

          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'140px'}>
              Kampagne id:{" "}
            </Text>
            <Input
              variant='flushed'
              type='text'
              name="partnerApiProduct"
              defaultValue={data.affiliatePartnerProduct.partnerApiProduct}
              onBlur={e => (dataUpdate(data.affiliatePartnerProduct.id, 'partnerApiProduct', e.target.value))}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ArosForsikringIntegrationInfo;