import { gql } from "@apollo/client";

export const GET_AFFILIATEPARTNER = gql`
query GET_AFFILIATEPARTNER($id: ID!){
  affiliatePartner(id:$id){
    id
    active
    loginAsUrl
    address{
      id
      roadNameAndNumber
      zipCode 
      city
    }
    documents{
      id
      filename
      orgDocStorageUrl   
    }
    cvr 
    phone
    email
    financialEmail
    name
    contactName
    note
    websiteUrl
    logoMedia{
      id
      filename
      mediaUrl
    }
    products{
      id
      apiName
      name
      integrationType
      billingType
    }
  }
}`
