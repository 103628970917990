// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateRealEstateBrokerOfficeInfoGql = gql`
  mutation updateRealEstateBrokerOfficeInfo($input: UpdateRealEstateBrokerOffice!) {
    updateRealEstateBrokerOffice(input: $input) {
      id
    }
  }
`;

const EstateBrokerOfficeInfo = ({ data }) => {
  const toast = useToast();
  const [updateRealEstateBrokerOfficeInfo] = useMutation(updateRealEstateBrokerOfficeInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (realEstateBrokerOfficeId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = realEstateBrokerOfficeId;
    updateRealEstateBrokerOfficeInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Butiksoplysninger
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.realEstateBrokerOffice.name}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "name", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Kontaktperson:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.realEstateBrokerOffice.contactName}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "contactName", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Butiks nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="shopNo"
              defaultValue={data.realEstateBrokerOffice.shopNo}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "shopNo", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Telefon nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="phone"
              defaultValue={data.realEstateBrokerOffice.phone}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "phone", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              E-mail:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="email"
              defaultValue={data.realEstateBrokerOffice.email}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "email", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              CVR:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="cvr"
              defaultValue={data.realEstateBrokerOffice.cvr}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "cvr", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Website:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="websiteUrl"
              defaultValue={data.realEstateBrokerOffice.websiteUrl}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "websiteUrl", e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EstateBrokerOfficeInfo;
