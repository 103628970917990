// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure
} from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../components/estateBrokerHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import SettleTable from "../../../../components/Tables/SettleTable";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { useHistory } from "react-router-dom";

const GET_ESTATEBROKEROFFICES = gql`
  query GET_ESTATEBROKEROFFICES($id: ID!) {
    realEstateBroker(id: $id) {
      name
      offices {
        id
        name
        address {
          fullAddress
        }
        cvr
        email
        realEstateBroker {
          id
        }
      }
    }
  }
`;

const CREATE_RealEstateBrokerOfficeGql = gql`
  mutation createRealEstateBrokerOffice($input: CreateRealEstateBrokerOfficeInput!) {
    createRealEstateBrokerOffice(input: $input) {
      id
    }
  }
`;

function RealEstateBrokerOffices() {
  // Chakra color mode
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const cancelRefCreateDialog = React.useRef();
  const newNameRef = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();
  const [createEstateBrokerOffice] = useMutation(CREATE_RealEstateBrokerOfficeGql, { refetchQueries: [GET_ESTATEBROKEROFFICES] });

  const { estateBrokerId } = useParams();
  const { loading, error, data } = useQuery(GET_ESTATEBROKEROFFICES, {
    variables: { id: estateBrokerId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Adresse",
      accessor: "address.fullAddress",
      onClick: rowClicked,
    },
    {
      header: "Email",
      accessor: "email",
      onClick: rowClicked,
    },
    {
      header: "CVR",
      accessor: "cvr",
      onClick: rowClicked,
    },
    {
      header: "realEstateBrokerId",
      accessor: "realEstateBroker.id",
      hidden: true,
    },
  ];

  function rowClicked(obj) {
    const estateBrokerId = obj["realEstateBroker.id"];
    const estateBrokerOfficeId = obj.id;
    history.push("/admin/ejendomsmaegler/" + estateBrokerId + "/office/" + estateBrokerOfficeId + "/info");
  }


  const showCreateModal = () => {
    onOpenCreateDialog();
  }

  const createEstateOffice = () => {
    let inputData = {};
    inputData.realEstateBrokerId = estateBrokerId;
    inputData.name = newNameRef.current.value;

    createEstateBrokerOffice({
      variables: { input: inputData },
    });

    onCloseCreateDialog();
  };

  return (
    <>
      <Flex direction="column">
        <Header
          backgroundHeader={ProfileBgImage}
          backgroundProfile={bgProfile}
          avatarImage={avatar4}
          name={data.realEstateBroker.name}
          description={"Butikker"}
          view={"offices"}
          estateBrokerId={estateBrokerId}
        />
        <Card px="0px">
          <CardHeader px="22px" mb="24px" justifyContent="space-between">
            <Flex direction="column">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                Butikker
            </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Liste over butikker
            </Text>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="6px"
                cursor="pointer"
                onClick={() => {
                  showCreateModal();
                }}
              >
                Opret ny butik
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <SettleTable data={data.realEstateBroker.offices} columnsData={columns} />
          </CardBody>
        </Card>
      </Flex>

      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret ny butik
</AlertDialogHeader>

            <AlertDialogBody>
              Du er ved at oprette en ny butik i systemet.
  <br />
              <br />
  Angiv navn:
  <Input ref={newNameRef} type="text" name="name" defaultValue="" />
              <br />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
  </Button>
              <Button colorScheme="green" onClick={createEstateOffice} ml={3}>
                Opret
  </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default RealEstateBrokerOffices;
