import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";
import { MdDeleteOutline, MdEditNote } from "react-icons/md";

import { Icon, Td, Tr, Checkbox } from "@chakra-ui/react";
const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

export const DraggableTableRow = ({ row, headerGroups, enableSorting, enableSelect, selectedChanged }) => {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  let isSelected = false;
  if (row.original.selected) {
    isSelected = row.original.selected;
  }

  return (
    <Tr {...row.getRowProps()} ref={setNodeRef} style={style}>
      {enableSelect && (
        <Td style={{ background: "#00000", paddingLeft: "10px", paddingRight: "0px", width: "24px" }}>
          <Checkbox style={{ height: '18px' }} iconColor='gray.800' colorScheme="white" isChecked={isSelected} onChange={(e) => selectedChanged(row.original.id, e.currentTarget.checked)}></Checkbox>
        </Td>
      )}
      {enableSorting && (
        <Td style={{ background: "#00000", paddingLeft: "10px", paddingRight: "0px", width: "24px" }}>
          <DragHandle {...attributes} {...listeners} />
        </Td>
      )}
      {isDragging ? (
        <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
      ) : (
        row.cells.map((cell, i) => {
          if (headerGroups[0].headers[i].hidden === false || headerGroups[0].headers[i].hidden === undefined) {
            //if (i === 0) {
            if (headerGroups[0].headers[i].onClick) {
              var icon = null;
              if (headerGroups[0].headers[i].icon) {
                switch (headerGroups[0].headers[i].icon) {
                  case "delete":
                    icon = MdDeleteOutline;
                    break;
                  case "edit":
                    icon = MdEditNote;
                    break;
                  default:
                    break;
                }
              }

              return (
                <Td
                  {...cell.getCellProps()}
                  fontSize={{ sm: "14px" }}
                  key={i}
                  textAlign={headerGroups[0].headers[i].alignData ? headerGroups[0].headers[i].alignData : "left"}
                  style={headerGroups[0].headers[i].icon ? { cursor: "pointer", textAlign: "center" } : { cursor: "pointer" }}
                  onClick={() => {
                    headerGroups[0].headers[i].onClick(cell.row.values);
                  }}
                >
                  {headerGroups[0].headers[i].icon ? (
                    <Icon as={icon} w={{ sm: "16px", md: "16px" }} h={{ sm: "16px", md: "16px" }} color="gray.500"></Icon>
                  ) : (
                    cell.render("Cell")
                  )}
                </Td>
              );
            } else {
              return (
                <Td
                  {...cell.getCellProps()}
                  fontSize={{ sm: "14px" }}
                  key={i}
                  textAlign={headerGroups[0].headers[i].alignData ? headerGroups[0].headers[i].alignData : "left"}
                >
                  {headerGroups[0].headers[i].icon ? (
                    <Icon as={MdDeleteOutline} w={{ sm: "16px", md: "16px" }} h={{ sm: "16px", md: "16px" }} color="gray.500"></Icon>
                  ) : (
                    cell.render("Cell")
                  )}
                </Td>
              );
            }
          }
          return null;
        })
      )}
    </Tr>
  );
};
