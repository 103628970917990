// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Icon,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Select
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { FaRegEdit, FaCheck } from "react-icons/fa";
import { GET_BillingItemPricesGql, CREATE_BillingItemPriceGql, UPDATE_BillingItemPriceGql } from "./../AffiliatePartnerProduct/graphQL";
import { useQuery, useMutation } from "@apollo/client";
import { TbTrashX } from "react-icons/tb";

const AffiliatePartnerProductPricesV2 = ({ PartnerProductId }) => {
  const [rowId, setRowId] = useState(0);
  const cancelRef = React.useRef();
  const fromDateRef = React.useRef();
  const toDateRef = React.useRef();
  const realEstateBrokerRef = React.useRef();
  const priceRef = React.useRef();
  const realEstateBrokerEarningRef = React.useRef();
  const realEstateSystemEarningRef = React.useRef();
  const cancelRefCreateEditDialog = React.useRef();
  const earningRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenCreateEditDialog, onOpen: onOpenCreateEditDialog, onClose: onCloseCreateEditDialog } = useDisclosure();

  const [currentPriceObj, setCurrentPriceObj] = useState({
    id: null,
    deleted: false,
    affiliatePartnerProductId: PartnerProductId,
    realEstateBrokerId: "",
    fromDate: new Date().getFullYear() + '-01-01',
    toDate: new Date().getFullYear() + '-12-31',
    price: 0,
    realEstateBrokerEarning: 0,
    realEstateSystemEarning: 0,
    earning: 0,
    billingType: "ORDER",
    salesPriceLessThan: 0,
    salesPriceGreaterThan: 0
  });

  const textColor = useColorModeValue("gray.700", "white");

  const [createBillingItemPrice] = useMutation(CREATE_BillingItemPriceGql, { refetchQueries: [GET_BillingItemPricesGql] });
  const [updateBillingItemPrice] = useMutation(UPDATE_BillingItemPriceGql, { refetchQueries: [GET_BillingItemPricesGql] });

  const { loading, error, data } = useQuery(GET_BillingItemPricesGql, {
    variables: { id: PartnerProductId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting prices from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting prices from server (2)</p>;
  }

  const editPrices = (priceId) => {
    let price = data.billingItemPrices.find((x) => x.id === priceId);
    let priceObj = {
      id: price.id,
      deleted: false,
      affiliatePartnerProductId: PartnerProductId,
      realEstateBrokerId: price.realEstateBroker?.id,
      fromDate: price.fromDate,
      toDate: price.toDate,
      price: price.price,
      realEstateBrokerEarning: price.realEstateBrokerEarning,
      realEstateSystemEarning: price.realEstateSystemEarning,
      earning: price.earning,
      billingType: price.billingType,
      salesPriceLessThan: price.salesPriceLessThan,
      salesPriceGreaterThan: price.salesPriceGreaterThan

    }

    setCurrentPriceObj(priceObj);
    onOpenCreateEditDialog();
  };

  const deletePrice = (priceId) => {
    setRowId(priceId);
    onOpen();
  };

  const deleteRow = () => {
    onClose();
    let inputData = {};
    inputData.id = rowId;
    inputData.deleted = true;

    updateBillingItemPrice({
      variables: { input: inputData },
    });

    setRowId(0);
  };

  const openCreateNewPriceDialog = () => {
    setCurrentPriceObj({
      id: null,
      deleted: false,
      affiliatePartnerProductId: PartnerProductId,
      realEstateBrokerId: "",
      fromDate: new Date().getFullYear() + '-01-01',
      toDate: new Date().getFullYear() + '-12-31',
      price: 0,
      realEstateBrokerEarning: 0,
      realEstateSystemEarning: 0,
      earning: 0,
      billingType: "ORDER",
      salesPriceLessThan: 0,
      salesPriceGreaterThan: 0
    });
    onOpenCreateEditDialog();
  };

  const createEditPriceClicked = () => {

    //calulate the earning
    if (currentPriceObj.billingType === "KICKBACK_ORDER_PROCENT") {
      currentPriceObj.earning = 100 - (currentPriceObj.realEstateBrokerEarning + currentPriceObj.realEstateSystemEarning)
    } else {
      currentPriceObj.earning = currentPriceObj.price - currentPriceObj.realEstateBrokerEarning - currentPriceObj.realEstateSystemEarning;
    }

    if (currentPriceObj.id !== null) {
      updateBillingItemPrice({
        variables: { input: currentPriceObj },
      });
    } else {
      //remove id and deleted from object
      delete currentPriceObj.id;
      delete currentPriceObj.deleted;

      createBillingItemPrice({
        variables: { input: currentPriceObj },
      });
    }

    onCloseCreateEditDialog();
  };

  const updateFieldData = (field, value) => {

    if (["price", "realEstateBrokerEarning", "realEstateSystemEarning", "earning", "salesPriceLessThan", "salesPriceGreaterThan"].indexOf(field) > -1) {
      value = parseFloat(value);
    }

    currentPriceObj[field] = value;
    setCurrentPriceObj({ ...currentPriceObj });
  }

  const translateType = (price) => {
    switch (price.billingType) {
      case "ORDER":
        return "Pr. salg";
      case "LEAD":
        return "Pr. lead";
      case "KICKBACK_ORDER_INTERVAL":
        return "Pr. salg ud fra pris (" + price.salesPriceGreaterThan + " - " + price.salesPriceLessThan + ")";
      case "KICKBACK_ORDER_PROCENT":
        return "Pr. salg, procent af pris";
      default:
        return "";
    }
  }

  return (
    <div>
      <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Priser
            </Text>
          </Flex>
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" cursor="pointer" onClick={() => openCreateNewPriceDialog()}>
              Tilføj ny pris
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <TableContainer w="100%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Fra</Th>
                  <Th>Til</Th>
                  <Th>Mæglerkæde</Th>
                  <Th>Type</Th>
                  <Th isNumeric>Partner betaling</Th>
                  <Th isNumeric>Ejendomsmægler fee</Th>
                  <Th isNumeric>Mæglersystem fee</Th>
                  <Th isNumeric>Fortjeneste</Th>
                  <Th w="20px">Slet</Th>
                  <Th w="20px">Redigere</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.billingItemPrices.map((row) => {

                  if (row.billingType === "KICKBACK_ORDER_PROCENT") {
                    return (
                      <Tr key={row.id}>
                        <Td>{row.fromDate}</Td>
                        <Td>{row.toDate}</Td>
                        {row.realEstateBroker !== null ? <Td>{row.realEstateBroker.name}</Td> : <Td>-</Td>}
                        <Td>{translateType(row)}</Td>
                        <Td isNumeric>{row.price} %</Td>
                        <Td isNumeric>{row.realEstateBrokerEarning} %</Td>
                        <Td isNumeric>{row.realEstateSystemEarning} %</Td>
                        <Td isNumeric>{row.earning} %</Td>
                        <Td textAlign={"center"}>
                          <Icon as={TbTrashX} me="10px" style={{ cursor: "pointer" }} onClick={() => deletePrice(row.id)} />
                        </Td>
                        <Td textAlign={"center"}>
                          <Icon as={FaRegEdit} me="10px" style={{ cursor: "pointer" }} onClick={() => editPrices(row.id)} />
                        </Td>
                      </Tr>
                    );
                  } else {
                    return (
                      <Tr key={row.id}>
                        <Td>{row.fromDate}</Td>
                        <Td>{row.toDate}</Td>
                        {row.realEstateBroker !== null ? <Td>{row.realEstateBroker.name}</Td> : <Td>-</Td>}
                        <Td>{translateType(row)}</Td>
                        <Td isNumeric>{row.price}</Td>
                        <Td isNumeric>{row.realEstateBrokerEarning}</Td>
                        <Td isNumeric>{row.realEstateSystemEarning}</Td>
                        <Td isNumeric>{row.earning}</Td>
                        <Td textAlign={"center"}>
                          <Icon as={TbTrashX} me="10px" style={{ cursor: "pointer" }} onClick={() => deletePrice(row.id)} />
                        </Td>
                        <Td textAlign={"center"}>
                          <Icon as={FaRegEdit} me="10px" style={{ cursor: "pointer" }} onClick={() => editPrices(row.id)} />
                        </Td>
                      </Tr>
                    );
                  }
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet pris
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteRow} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>





      <AlertDialog isOpen={isOpenCreateEditDialog} leastDestructiveRef={cancelRefCreateEditDialog} onClose={onCloseCreateEditDialog} size="2xl" >
        <AlertDialogOverlay>
          <AlertDialogContent >

            {currentPriceObj.id !== null ? (
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Opdatere priser.
              </AlertDialogHeader>
            ) : (
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Opret nye priser.
              </AlertDialogHeader>
            )}

            <AlertDialogBody>
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Lead type:{" "}
                  </Text>
                  <Select
                    name="billingType"
                    defaultValue={currentPriceObj.billingType}
                    onChange={(e) => updateFieldData("billingType", e.target.value)}
                  >
                    <option value="ORDER">Pr. salg</option>
                    <option value="LEAD">Pr. lead</option>
                    <option value="KICKBACK_ORDER_INTERVAL">Pr. salg ud fra pris</option>
                    <option value="KICKBACK_ORDER_PROCENT">Pr. salg, procent af pris</option>
                  </Select>
                </Flex>

                {["KICKBACK_ORDER_INTERVAL", "ORDER", "LEAD"].indexOf(currentPriceObj.billingType) > -1 && (
                  <>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Fra:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="text"
                        name="fromDate"
                        defaultValue={currentPriceObj.fromDate}
                        onBlur={(e) => updateFieldData("fromDate", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Til:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="text"
                        name="toDate"
                        defaultValue={currentPriceObj.toDate}
                        onBlur={(e) => updateFieldData("toDate", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Mæglerkæde:{" "}
                      </Text>
                      <Select multiple={false} defaultValue={currentPriceObj.realEstateBrokerId} onChange={(e) => updateFieldData("realEstateBrokerId", e.target.value)}>
                        <option key={null} value="">
                          Ingen
                            </option>
                        {data.realEstateBrokers.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Partner betaling:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="price"
                        defaultValue={currentPriceObj.price}
                        onBlur={(e) => updateFieldData("price", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Ejendomsmægler fee:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="realEstateBrokerEarning"
                        defaultValue={currentPriceObj.realEstateBrokerEarning}
                        onBlur={(e) => updateFieldData("realEstateBrokerEarning", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Mæglersystem fee:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="realEstateSystemEarning"
                        defaultValue={currentPriceObj.realEstateSystemEarning}
                        onBlur={(e) => updateFieldData("realEstateSystemEarning", e.target.value)}
                      />
                    </Flex>

                  </>
                )}

                {currentPriceObj.billingType === "KICKBACK_ORDER_INTERVAL" && (
                  <>

                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Pris større end:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="salesPriceGreaterThan"
                        defaultValue={currentPriceObj.salesPriceGreaterThan}
                        onBlur={(e) => updateFieldData("salesPriceGreaterThan", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Pris mindre end:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="salesPriceLessThan"
                        defaultValue={currentPriceObj.salesPriceLessThan}
                        onBlur={(e) => updateFieldData("salesPriceLessThan", e.target.value)}
                      />
                    </Flex>

                  </>
                )}

                {currentPriceObj.billingType === "KICKBACK_ORDER_PROCENT" && (
                  <>

                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Fra:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="text"
                        name="fromDate"
                        defaultValue={currentPriceObj.fromDate}
                        onBlur={(e) => updateFieldData("fromDate", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Til:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="text"
                        name="toDate"
                        defaultValue={currentPriceObj.toDate}
                        onBlur={(e) => updateFieldData("toDate", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                        Mæglerkæde:{" "}
                      </Text>
                      <Select multiple={false} defaultValue={currentPriceObj.realEstateBrokerId} onChange={(e) => updateFieldData("realEstateBrokerId", e.target.value)}>
                        <option key={null} value="">
                          Ingen
                            </option>
                        {data.realEstateBrokers.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"300px"}>
                        Partner betaling % af køb:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="price"
                        defaultValue={currentPriceObj.price}
                        onBlur={(e) => updateFieldData("price", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"300px"}>
                        Ejendomsmægler fee % af partner beløb:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="realEstateBrokerEarning"
                        defaultValue={currentPriceObj.realEstateBrokerEarning}
                        onBlur={(e) => updateFieldData("realEstateBrokerEarning", e.target.value)}
                      />
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"300px"}>
                        Mæglersystem fee % af partner beløb:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        type="number"
                        name="realEstateSystemEarning"
                        defaultValue={currentPriceObj.realEstateSystemEarning}
                        onBlur={(e) => updateFieldData("realEstateSystemEarning", e.target.value)}
                      />
                    </Flex>

                  </>
                )}
              </Flex>

            </AlertDialogBody>
            <AlertDialogFooter>
              <Button

                onClick={() => {
                  onCloseCreateEditDialog();
                }}
              >
                Fortryd
              </Button>
              {currentPriceObj.id !== null ? (
                <Button colorScheme="green" onClick={createEditPriceClicked} ml={3}>
                  Opdatere
                </Button>
              ) : (
                <Button colorScheme="green" onClick={createEditPriceClicked} ml={3}>
                  Opret
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default AffiliatePartnerProductPricesV2;
