export var itemType;
(function (itemType) {
    itemType["NORMAL"] = "NORMAL";
    itemType["HANDOVER_ELECTRICITY"] = "HANDOVER_ELECTRICITY";
    itemType["HANDOVER_WATER"] = "HANDOVER_WATER";
    itemType["HANDOVER_WASTEWATER"] = "HANDOVER_WASTEWATER";
    itemType["HANDOVER_HEAT"] = "HANDOVER_HEAT";
    itemType["HANDOVER_SESSION"] = "HANDOVER_SESSION";
    itemType["HANDOVER_PERSONS"] = "HANDOVER_PERSONS";
    itemType["HANDOVER_SIGNING"] = "HANDOVER_SIGNING";
})(itemType || (itemType = {}));
/*
export type HandoverElectricity = {
    meterNo: string
    kWh: string
    readingDate: string
    imageBase64: string | null
    imageUrl: string
    supplier: string
    notes: string
    formFilled: false,
}
*/
