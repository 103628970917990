// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "./../components/estateBrokerHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import EstateBrokerInfo from "./../components/estateBrokerInfo"
import EstateBrokerNotes from "./../components/estateBrokerNotes"
import EstateBrokerAddress from "./../components/estateBrokerAddress"
import EstateBrokerDocuments from "./../components/estateBrokerDocuments"
import EstateBrokerBankAccountInfo from "./../components/estateBrokerBankAccountInfo"
import EstateBrokerMedia from "../components/estateBrokerMedia";

const GET_ESTATEBROKER = gql`
query GET_ESTATEBROKER($id: ID!){
  realEstateBroker(id:$id){
    id
    active
    loginAsUrl
    address{
      roadNameAndNumber
      zipCode 
      city
    }
    documents{
      id
      filename
      orgDocStorageUrl   
    }
    cvr
    phone
    email
    name
    note
    websiteUrl
    bankRegistrationNumber
    bankAccountNumber
    bankName
    mainColor
    estateSystemId
    contactName
    logo {
      id
      filename
      mediaUrl
    }
    plannerTop {
      id
      filename
      mediaUrl
    }
  }
  estateSystems {
    id
    name
  }
}`

const SENT_WELCOMEMAIL_ESTATEBROKER = gql`
  mutation sentEstateBrokerWelcomeMail($estateBrokerId: ID!) {
    sentEstateBrokerWelcomeMail(estateBrokerId: $estateBrokerId)
  }
`;

function Info() {
  // Chakra color mode
  const toast = useToast();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [sentWelcomeMail] = useMutation(SENT_WELCOMEMAIL_ESTATEBROKER);
  const { estateBrokerId } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_ESTATEBROKER, {
    variables: { id: estateBrokerId },
  }, {
    manual: true,
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting stats from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting stats from server (2)</p>);
  }

  const handleSentWelcomeMailClicked = () => {
    sentWelcomeMail({ variables: { estateBrokerId: estateBrokerId } }).then(() => {
      toast({
        title: "Der er nu sendt en velkomst mail til mægleren.",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, der blev IKKE sendt en velkomst mail til mægleren.",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  function forceRerender() {
    refetch()
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.realEstateBroker.name}
        description={"Overblik"}
        view={"info"}
        estateBrokerId={estateBrokerId}
        onSentWellcomesMessageClicked={handleSentWelcomeMailClicked}
        loginAsUrl={data.realEstateBroker.loginAsUrl}

      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap="22px">
        <EstateBrokerInfo data={data} forceRerender={forceRerender} />

        <GridItem colSpan={1} rowSpan={0} >
          <EstateBrokerAddress data={data} />
          <br />
          <EstateBrokerBankAccountInfo data={data} />
        </GridItem>

        <EstateBrokerDocuments data={data.realEstateBroker} forceRerender={forceRerender} />
        <EstateBrokerNotes data={data} />

        <EstateBrokerMedia data={data.realEstateBroker} forceRerender={forceRerender} mediaType={'LOGO'} />
        <EstateBrokerMedia data={data.realEstateBroker} forceRerender={forceRerender} mediaType={'PLANNER_TOP'} />

      </Grid>
    </Flex>
  );
}

export default Info;
