// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast, Select } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";

const updateRealEstateBrokerInfoGql = gql`
  mutation updateRealEstateBrokerInfo($input: UpdateRealEstateBroker!) {
    updateRealEstateBroker(input: $input) {
      id
    }
  }
`;

const EstateBrokerInfo = ({ data, forceRerender }) => {
  const toast = useToast();
  const [updateRealEstateBrokerInfo] = useMutation(updateRealEstateBrokerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (estateBrokerId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = estateBrokerId;
    updateRealEstateBrokerInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const updateEstateSystem = (e) => {
    updateData(data.realEstateBroker.id, "estateSystemId", e.target.value)
    setTimeout(() => {
      forceRerender();
    }, 300);
  }

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Mæglerkæde
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.realEstateBroker.name}
              onBlur={(e) => updateData(data.realEstateBroker.id, "name", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Kontaktperson:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.realEstateBroker.contactName}
              onBlur={(e) => updateData(data.realEstateBroker.id, "contactName", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Telefon nr:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="phone"
              defaultValue={data.realEstateBroker.phone}
              onBlur={(e) => updateData(data.realEstateBroker.id, "phone", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              E-mail:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="email"
              defaultValue={data.realEstateBroker.email}
              onBlur={(e) => updateData(data.realEstateBroker.id, "email", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              CVR:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="cvr"
              defaultValue={data.realEstateBroker.cvr}
              onBlur={(e) => updateData(data.realEstateBroker.id, "cvr", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Website:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="websiteUrl"
              defaultValue={data.realEstateBroker.websiteUrl}
              onBlur={(e) => updateData(data.realEstateBroker.id, "websiteUrl", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Primær farve:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="mainColor"
              defaultValue={data.realEstateBroker.mainColor}
              onBlur={(e) => updateData(data.realEstateBroker.id, "mainColor", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Mægler system:{" "}
            </Text>
            <Select
              multiple={false}
              value={data.realEstateBroker.estateSystemId}
              onChange={(e) => {
                updateEstateSystem(e);
              }}
            >
              {data.estateSystems.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EstateBrokerInfo;
