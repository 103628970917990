/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import CardHeader from "./../../../components/Card/CardHeader";
import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SettleTable from "./../../../components/Tables/SettleTable";
import { useHistory } from "react-router-dom";

const GET_AffiliatePartnerGql = gql`
  query affiliatePartners {
    affiliatePartners {
      id
      active
      address {
        id
        fullAddress
      }
      cvr
      email
      financialEmail
      name
    }
  }
`;

const CREATE_AffiliatePartnerGql = gql`
  mutation createAffiliatePartner($input: CreateAffiliatePartner!) {
    createAffiliatePartner(input: $input) {
      id
    }
  }
`;

function AffiliatePartner() {
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const cancelRefCreateDialog = React.useRef();
  const newPartnerNameRef = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();

  const [createAffiliatePartner] = useMutation(CREATE_AffiliatePartnerGql, { refetchQueries: [GET_AffiliatePartnerGql] });

  const { loading, error, data } = useQuery(GET_AffiliatePartnerGql, {
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "NAVN",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "ADDRESSE",
      accessor: "address.fullAddress",
      onClick: rowClicked,
    },
    {
      header: "EMAIL",
      accessor: "email",
      onClick: rowClicked,
    },
    {
      header: "CVR",
      accessor: "cvr",
      onClick: rowClicked,
    },
  ];

  function rowClicked(obj) {
    history.push("/admin/partner/" + obj.id + "/info");
  }

  const showCreateModal = () => {
    onOpenCreateDialog();
  };

  const createPartner = () => {
    let inputData = {};
    inputData.name = newPartnerNameRef.current.value;

    createAffiliatePartner({
      variables: { input: inputData },
    });

    onCloseCreateDialog();
  };

  return (
    <div>
      <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="24px" justifyContent="space-between">
            <Flex direction="column">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                Partnere
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Liste over samarbejdspartnere.
              </Text>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="6px"
                cursor="pointer"
                onClick={() => {
                  showCreateModal();
                }}
              >
                Opret ny partner
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <SettleTable data={data.affiliatePartners} columnsData={columns} />
          </CardBody>
        </Card>
      </Flex>

      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret ny partner
            </AlertDialogHeader>

            <AlertDialogBody>
              Du er ved at oprette en ny partner i systemet.
              <br />
              <br />
              Angiv partner navn:
              <Input ref={newPartnerNameRef} type="text" name="partnerName" defaultValue="" />
              <br />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={createPartner} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default AffiliatePartner;
