import { datastore } from './dataStore';
export function MapToInternalTypes(GraphQLResult) {
    //console.log('mapped input:', GraphQLResult)
    const customer = {
        id: GraphQLResult.id,
        name: GraphQLResult.name,
        email: GraphQLResult.email,
        phone: GraphQLResult.phone,
        hasConsent: GraphQLResult.hasConsent,
        realEstateBrokerCases: MapRealEsteateCases(GraphQLResult.realEstateBrokerCases),
    };
    datastore.data.userId = GraphQLResult.id;
    datastore.data.userName = GraphQLResult.name;
    datastore.data.userEmail = GraphQLResult.email;
    datastore.data.userPhone = GraphQLResult.phone;
    datastore.data.userAddress = GraphQLResult.realEstateBrokerCases[0].movingDetails.moveToAddress.fullAddress;
    datastore.data.userZipCode = GraphQLResult.realEstateBrokerCases[0].movingDetails.moveToAddress.zipCode;
    datastore.data.userCity = GraphQLResult.realEstateBrokerCases[0].movingDetails.moveToAddress.city;
    //console.log('mapped result:', customer)
    return customer;
}
function MapRealEsteateCases(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const realEstateBrokerCase = {
            id: GraphQLResult[i].id,
            caseNo: GraphQLResult[i].caseNo,
            caseType: GraphQLResult[i].caseType,
            PropertyType: GraphQLResult[i].propertyType,
            primaryimageUrl: GraphQLResult[i].primaryimageUrl,
            salesPrice: GraphQLResult[i].salesPrice,
            status: GraphQLResult[i].status,
            address: MapAddress(GraphQLResult[i].address),
            movingDetails: MapMovingDetails(GraphQLResult[i].movingDetails),
        };
        result.push(realEstateBrokerCase);
    }
    return result;
}
function MapMovingDetails(GraphQLResult) {
    const customerMovingDetails = {
        currentAddress: MapAddress(GraphQLResult.currentAddress),
        customerType: GraphQLResult.customerType,
        dispositionDate: GraphQLResult.dispositionDate,
        id: GraphQLResult.id,
        moveInDate: GraphQLResult.moveInDate,
        moveOutDate: GraphQLResult.moveOutDate,
        moveToAddress: MapAddress(GraphQLResult.moveToAddress),
        takeoverDate: GraphQLResult.takeoverDate,
        plannerCategories: MapPlannerCategories(GraphQLResult.plannerCategories),
        handover: MapHandover(GraphQLResult.handover),
    };
    return customerMovingDetails;
}
function MapPlannerCategories(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const rootCollapsible = {
            id: GraphQLResult[i].id,
            description: GraphQLResult[i].subHeading,
            title: GraphQLResult[i].heading,
            isEditMode: false,
            isOpen: false,
            items: MapPlannerItems(GraphQLResult[i].plannerItems),
            customerItems: MapPlannerCustomerItems(GraphQLResult[i].plannerItemsCustom),
        };
        result.push(rootCollapsible);
    }
    return result;
}
function MapPlannerCustomerItems(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const customerItem = {
            id: GraphQLResult[i].id,
            heading: GraphQLResult[i].heading,
            text: GraphQLResult[i].text,
            completed: GraphQLResult[i].completed,
            isRemoved: GraphQLResult[i].removed,
        };
        result.push(customerItem);
    }
    return result;
}
function MapPlannerItems(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const itemCollapsible = {
            id: GraphQLResult[i].id,
            heading: GraphQLResult[i].heading,
            subHeading: GraphQLResult[i].subHeading,
            description: GraphQLResult[i].text,
            completed: GraphQLResult[i].completed,
            isOpen: false,
            isRemoved: GraphQLResult[i].removed,
            productCategoriesTitle: GraphQLResult[i].text,
            icon: MapMedia(GraphQLResult[i].icon),
            media: MapMedia(GraphQLResult[i].media),
            productCategories: MapProductCategories(GraphQLResult[i].productCategories),
            links: MapItemLinks(GraphQLResult[i].links),
            hasProducts: GraphQLResult[i].hasProducts,
            itemType: GraphQLResult[i].itemType,
            productCategoryText: GraphQLResult[i].productCategoryText,
        };
        result.push(itemCollapsible);
    }
    return result;
}
function MapItemLinks(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const itemLink = {
            id: GraphQLResult[i].id,
            text: GraphQLResult[i].text,
            url: GraphQLResult[i].url,
            logo: MapMedia(GraphQLResult[i].logo),
            sorting: GraphQLResult[i].sorting,
        };
        result.push(itemLink);
    }
    return result;
}
function MapProductCategories(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const productCategory = {
            id: GraphQLResult[i].id,
            heading: GraphQLResult[i].heading,
            text: GraphQLResult[i].text,
            name: GraphQLResult[i].name,
            iconMedia: MapMedia(GraphQLResult[i].icon),
            buttonText: GraphQLResult[i].buttonText,
            media: MapMedia(GraphQLResult[i].media),
            products: MapProducts(GraphQLResult[i].products),
            links: MapItemLinks(GraphQLResult[i].links),
            completed: GraphQLResult[i].completed,
            notRelevant: GraphQLResult[i].notRelevant,
        };
        result.push(productCategory);
    }
    return result;
}
function MapProducts(GraphQLResult) {
    const result = [];
    for (let i = 0; i < GraphQLResult.length; i++) {
        const product = {
            id: GraphQLResult[i].id,
            description: '',
            partnerName: GraphQLResult[i].partnerName,
            partnerLogo: MapMedia(GraphQLResult[i].partnerLogo),
            name: GraphQLResult[i].name,
            ordered: GraphQLResult[i].ordered,
            leadId: GraphQLResult[i].leadId,
            selected: false,
            disabled: false,
            hasPowerOfAttorneyDocument: GraphQLResult[i].hasPowerOfAttorneyDocument,
        };
        result.push(product);
    }
    return result;
}
function MapMedia(GraphQLResult) {
    if (!GraphQLResult) {
        return null;
    }
    const media = {
        id: GraphQLResult.id,
        mediaUrl: GraphQLResult.mediaUrl,
        text: GraphQLResult.text,
        heading: GraphQLResult.heading,
        mimeType: GraphQLResult.mimeType,
    };
    return media;
}
function MapAddress(GraphQLResult) {
    if (!GraphQLResult) {
        return null;
    }
    const address = {
        city: GraphQLResult.city,
        door: GraphQLResult.door,
        floor: GraphQLResult.floor,
        fullAddress: GraphQLResult.fullAddress,
        GeoCoordinat_Lat: GraphQLResult.GeoCoordinat_Lat,
        GeoCoordinat_Lng: GraphQLResult.GeoCoordinat_Lng,
        houseNumber: GraphQLResult.houseNumber,
        id: GraphQLResult.id,
        kvhx: GraphQLResult.kvhx,
        locationCityName: GraphQLResult.locationCityName,
        roadName: GraphQLResult.roadName,
        roadNameAndNumber: GraphQLResult.roadNameAndNumber,
        zipCode: GraphQLResult.zipCode,
    };
    return address;
}
//Handover
function MapHandover(GraphQLResult) {
    if (!GraphQLResult) {
        return null;
    }
    const handover = {
        id: GraphQLResult.id,
        // status: GraphQLResult.status,
        // electricity: MapHandoverElectricity(GraphQLResult.electricity)
    };
    datastore.data.handover.id = GraphQLResult.id;
    datastore.data.handover.status = GraphQLResult.status;
    return handover;
}
/*
function MapHandoverElectricity(GraphQLResult: any): HandoverElectricity | null {
  if (!GraphQLResult) {
    return null
  }
  const electricity = {
    kWh: GraphQLResult.kWh,
    meterNo: GraphQLResult.meterNo,
    readingDate: GraphQLResult.readingDate,
    imageBase64: null,
    imageUrl: GraphQLResult.imageUrl,
    supplier: GraphQLResult.supplier,
    notes: GraphQLResult.notes,
  } as HandoverElectricity

  datastore.data.handover.electricity.kWh = GraphQLResult.kWh;
  datastore.data.handover.electricity.meterNo = GraphQLResult.meterNo;
  datastore.data.handover.electricity.readingDate = GraphQLResult.readingDate;
  datastore.data.handover.electricity.imageUrl = GraphQLResult.imageUrl;
  datastore.data.handover.electricity.supplier = GraphQLResult.supplier;
  datastore.data.handover.electricity.notes = GraphQLResult.notes;

  return electricity
}
*/
