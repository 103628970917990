import { __awaiter } from "tslib";
import React, { useState } from 'react';
import './EditDataOverlay.scss';
import DatePicker from '../../../../components/molecules/date-picker/date-picker';
import DawaAutocomplete2 from '../../../../components/molecules/dawa-autocomplete2';
import Button from '../../../../components/atoms/button/button';
import { updateCustomerCaseInfoGql, closeCustomerAccountGql } from './../../graphQL';
import { useMutation, useQuery } from '@apollo/client';
import { datastore } from '../../../../dataStore';
import { GET_LEADS_GQL } from './graphQL';
var TabMenuItem;
(function (TabMenuItem) {
    TabMenuItem[TabMenuItem["Profile"] = 0] = "Profile";
    TabMenuItem[TabMenuItem["Services"] = 1] = "Services";
    TabMenuItem[TabMenuItem["PersonalData"] = 2] = "PersonalData";
})(TabMenuItem || (TabMenuItem = {}));
const EditDataOverlay = (props) => {
    const { data: leadsData, refetch } = useQuery(GET_LEADS_GQL, {
        variables: { id: props.data.id },
        fetchPolicy: 'no-cache',
    });
    const [showCancelLead, setshowCancelLead] = useState(false);
    const [selectedProduct, setselectedProduct] = useState();
    const [selectTap, setSelectTap] = useState(TabMenuItem.Profile);
    const [updateCustomerCaseInfo] = useMutation(updateCustomerCaseInfoGql, {});
    const [closeCustomerAccountInfo] = useMutation(closeCustomerAccountGql, {});
    const [takeoverDate, setTakeoverDate] = useState(new Date(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.takeoverDate));
    const [email, setEmail] = useState(props.data.email);
    const [phone, setPhone] = useState(props.data.phone);
    const [moveToAddress, setMoveToAddress] = useState(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.moveToAddress.fullAddress);
    const [moveFromAddress, setMoveFromAddress] = useState(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.currentAddress.fullAddress);
    const [validate, setValidate] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(props.data.hasConsent);
    const [isCloseAccountChecked, setIsCloseAccountChecked] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const handleMoveToAddressChanged = (fulladdress) => {
        setMoveToAddress(fulladdress);
    };
    const handleMoveFromAddressChanged = (fulladdress) => {
        setMoveFromAddress(fulladdress);
    };
    const handleDaySelect = (field, date) => {
        if (date) {
            console.log('field:', field);
            setTakeoverDate(new Date(date));
        }
    };
    const updateField = (fieldName, value) => {
        switch (fieldName) {
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };
    const handleConsentCheckBoxClicked = () => {
        setIsConsentChecked(!isConsentChecked);
    };
    const handleCheckBoxClicked = () => {
        setIsCloseAccountChecked(!isCloseAccountChecked);
    };
    const handleCloseAccountClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.id = props.data.id;
        //inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id
        //inputData.hasConsent = false
        yield closeCustomerAccountInfo({ variables: { input: inputData } });
        localStorage.removeItem('state');
        document.location.href = '/';
    });
    const handleSaveClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        //alert(moveFromAddress)
        if (isCloseAccountChecked) {
            setShowDeleteWarning(true);
        }
        else {
            switch (selectTap) {
                case TabMenuItem.Profile:
                    if (moveToAddress == '' ||
                        moveFromAddress == '' ||
                        email == '' ||
                        email.indexOf('@') == -1 ||
                        phone == '' ||
                        takeoverDate.toString() == new Date('1970-01-01').toString()) {
                        setValidate(true);
                    }
                    else {
                        const inputData = {};
                        inputData.id = props.data.id;
                        inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id;
                        inputData.moveToFullAddress = moveToAddress;
                        inputData.currentFullAddress = moveFromAddress;
                        inputData.takeoverDate = takeoverDate;
                        inputData.phone = phone;
                        inputData.email = email;
                        yield updateCustomerCaseInfo({ variables: { input: inputData } });
                        document.location.reload();
                    }
                    break;
                case TabMenuItem.PersonalData:
                    if (email == '' || email.indexOf('@') == -1 || phone == '') {
                        setValidate(true);
                    }
                    else {
                        const inputData = {};
                        inputData.id = props.data.id;
                        inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id;
                        inputData.hasConsent = isConsentChecked;
                        inputData.consentText = datastore.data.marketingConsentText;
                        yield updateCustomerCaseInfo({ variables: { input: inputData } });
                        document.location.reload();
                    }
                    break;
                default:
                    break;
            }
        }
    });
    const dawaFieldMoveToClass = validate && moveToAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const dawaFieldMoveFromClass = validate && moveFromAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const takeoverDateValidateError = validate && takeoverDate.toString() == new Date('1970-01-01').toString() ? true : false;
    const eMailFieldClass = validate && (email == '' || email.indexOf('@') == -1) ? 'fieldinput-error' : 'fieldinput';
    const phoneFieldClass = validate && phone == '' ? 'fieldinput-error' : 'fieldinput';
    //const consentFieldValidateError = validate && !isConsentChecked ? 'red' : '#cfcfcf'
    if (!leadsData) {
        return React.createElement("div", null);
    }
    const handleTabClicked = (htmlTab, tab) => {
        setSelectTap(tab);
        document.querySelectorAll('.tabMenuItem').forEach((element) => {
            element.classList.remove('tabMenuSelected');
        });
        htmlTab.classList.add('tabMenuSelected');
    };
    const handleCancelLeadsClicked = (product) => {
        console.log('product:', product);
        setselectedProduct(product);
        setshowCancelLead(true);
        //alert('Cancel lead:' + leadId)
    };
    const handleCancelLeadClicked = () => {
        setshowCancelLead(false);
        props.onCancelLead(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.leadId);
        refetch();
    };
    const handleCancelLeadCloseClicked = () => {
        setshowCancelLead(false);
    };
    console.log('takeoverDate:', takeoverDate);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'editDataOverlay' },
            React.createElement("div", { className: 'overlay' }),
            React.createElement("div", { className: 'container' },
                React.createElement("div", { id: 'mdiv', onClick: props.onClose },
                    React.createElement("div", { className: 'mdiv' },
                        React.createElement("div", { className: 'md' }))),
                showDeleteWarning || showCancelLead ? (React.createElement(React.Fragment, null,
                    showCancelLead && (React.createElement("div", { className: 'box' },
                        React.createElement("div", { className: 'header' }, "Fortryd bestilling"),
                        React.createElement("div", { className: 'estateSelectorContainer' },
                            React.createElement("div", null,
                                React.createElement("div", { className: 'cancelDescription', dangerouslySetInnerHTML: {
                                        __html: 'Du er ved at fortryde din bestilling fra ' +
                                            (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                            ', om at de må kontakte dig<br/><br/>Har du endnu ikke været i kontakt med ' +
                                            (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                            ', informerer vi ' +
                                            (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                            ' om, at du ikke længere ønsker at blive kontaktet, og at dit samtykke skal trækkes tilbage.<br/><br/>Har du allerede indgået en aftale med ' +
                                            (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                            ', står aftalen ved magt. Du skal derfor selv kontakte ' +
                                            (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                            ' for at annullere din aftale<br/><br/>Er du sikker?',
                                    } }),
                                React.createElement("div", { style: { display: 'flex' } },
                                    React.createElement(Button, { text: 'Ja, jeg er sikker', onClick: () => {
                                            handleCancelLeadClicked();
                                        }, grey: true }),
                                    React.createElement("div", { style: { paddingRight: '10px' } }),
                                    React.createElement(Button, { text: 'Nej, behold bestilling', onClick: () => {
                                            handleCancelLeadCloseClicked();
                                        } })))))),
                    showDeleteWarning && (React.createElement("div", { className: 'box' },
                        React.createElement("div", { className: 'header' }, "Luk min konto"),
                        React.createElement("div", { className: 'description' },
                            "Du er i f\u00E6rd med at lukke din profil og anmode om anonymisering af dine data hos Settle og dets partner, der har modtaget dine oplysninger.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Har du allerede indg\u00E5et en aftale med en partner, st\u00E5r aftalen ved magt. Du skal derfor selv kontakte partneren for at annullere din aftale.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement("b", null, "Er du sikker?")),
                        React.createElement("div", { className: 'bottom' },
                            React.createElement("div", { className: 'buttons' },
                                React.createElement(Button, { onClick: props.onClose, text: 'Nej, behold konto', grey: true }),
                                React.createElement("div", { style: { width: '35px' } }, "\u00A0"),
                                React.createElement(Button, { onClick: handleCloseAccountClicked, text: 'Ja, jeg er sikker' }))))))) : (React.createElement("div", { className: 'box' },
                    React.createElement("div", { className: 'tabMenu' },
                        selectTap === TabMenuItem.Profile ? (React.createElement("div", { className: 'tabMenuItem tabMenuSelected', style: { width: '140px' }, onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.Profile) }, "Profil")) : (React.createElement("div", { className: 'tabMenuItem', style: { width: '140px' }, onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.Profile) }, "Profil")),
                        selectTap === TabMenuItem.Services ? (React.createElement("div", { className: 'tabMenuItem tabMenuSelected', style: { width: '220px' }, onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.Services) }, "Dine services")) : (React.createElement("div", { className: 'tabMenuItem', style: { width: '220px' }, onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.Services) }, "Dine services")),
                        selectTap === TabMenuItem.PersonalData ? (React.createElement("div", { className: 'tabMenuItem tabMenuSelected', onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.PersonalData) }, "Persondata")) : (React.createElement("div", { className: 'tabMenuItem', onClick: (event) => handleTabClicked(event.currentTarget, TabMenuItem.PersonalData) }, "Persondata"))),
                    selectTap === TabMenuItem.Profile && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'header', style: { width: '480px' } }, "Dine oplysninger"),
                        React.createElement("p", null, "Her kan du se og tilpasse dine flytteoplysninger."),
                        React.createElement("div", { className: 'fields' },
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Din nuv\u00E6rende adresse"),
                                React.createElement(DawaAutocomplete2, { select: (selected) => {
                                        handleMoveFromAddressChanged(selected.tekst);
                                    }, blur: (address) => {
                                        handleMoveFromAddressChanged(address);
                                    }, inputValue: props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.currentAddress
                                        .fullAddress, classes: {
                                        root: dawaFieldMoveFromClass,
                                        input: 'text-field-dawa',
                                        ul: 'ul',
                                        li: 'li',
                                        selected: 'selected',
                                    } })),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Din nye adresse"),
                                React.createElement(DawaAutocomplete2, { select: (selected) => {
                                        handleMoveToAddressChanged(selected.tekst);
                                    }, blur: (address) => {
                                        handleMoveToAddressChanged(address);
                                    }, inputValue: props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.moveToAddress
                                        .fullAddress, classes: {
                                        root: dawaFieldMoveToClass,
                                        input: 'text-field-dawa',
                                        ul: 'ul',
                                        li: 'li',
                                        selected: 'selected',
                                    } })),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Indflytningsdato"),
                                React.createElement(DatePicker, { selected: takeoverDate, fieldName: 'takeoverDate', onSelect: handleDaySelect, error: takeoverDateValidateError })),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Din email"),
                                React.createElement("input", { type: 'text', value: email, className: eMailFieldClass, onChange: (e) => updateField('email', e.target.value) })),
                            React.createElement("div", { className: 'field', style: { paddingBottom: '20px' } },
                                React.createElement("div", { className: 'fieldHeading' }, "Dit telefonnummer"),
                                React.createElement("input", { type: 'tel', value: phone, className: phoneFieldClass, onChange: (e) => updateField('phone', e.target.value) }))),
                        React.createElement("div", { className: 'bottom' },
                            React.createElement("div", { className: 'buttons' },
                                React.createElement(Button, { onClick: handleSaveClicked, text: 'Gem' }))))),
                    selectTap === TabMenuItem.Services && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'header' }, "Dine benyttet services"),
                        React.createElement("p", null, "Her kan du se de services, du har \u00F8nsket at g\u00F8re brug af i forbindelse med din flytning, s\u00E5 du nemt og hurtigt kan komme p\u00E5 plads i dit nye hjem."),
                        React.createElement("div", { className: 'description' },
                            leadsData.getLeadByCustomerId.length > 0 ? (React.createElement("div", { className: 'productContainer' }, leadsData.getLeadByCustomerId.map((lead) => (React.createElement("div", { className: 'productItem', key: lead.id },
                                React.createElement("div", { className: 'productPartnerLogoContainer' },
                                    React.createElement("div", { className: 'productPartnerLogoBox' },
                                        React.createElement("img", { src: lead.product.partnerLogo.mediaUrl, alt: 'partner logo', className: 'productPartnerLogo' })),
                                    React.createElement("div", null, lead.product.name)),
                                React.createElement(Button, { hideArrow: true, text: 'Annuller', grey: true, width: 150, onClick: () => {
                                        handleCancelLeadsClicked(lead.product);
                                    } })))))) : (React.createElement("div", { className: 'noServices' }, "Du har ikke valgt nogen services endnu.")),
                            React.createElement("p", null, "De partner du har valgt, vil kontakte dig vedr. deres services og ydelser. Har du fortrudt og ikke l\u00E6ngere \u00F8nsker at blive kontaktet, kan du til enhver tid tr\u00E6kke dit samtykke tilbage ved at klikke p\u00E5 \"Annuller\""),
                            React.createElement("p", null, "Har du allerede indg\u00E5et en aftale, vil denne st\u00E5 ved magt, og du vil derfor selv skulle kontakte den p\u00E5g\u00E6ldende partner med \u00F8nsket om at benytte din fortrydelsesret jf. deres betingelser.")))),
                    selectTap === TabMenuItem.PersonalData && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'header' }, "Behandling af persondata og samtykke"),
                        React.createElement("p", null, "Hos Settle prioriterer vi din databeskyttelse og sikkerhed meget h\u00F8jt - herunder kan du l\u00E6se mere om dine samtykker samt, hvordan vi hos Settle behandler dine personoplysninger, n\u00E5r du bruger vores produkter og services."),
                        React.createElement("h2", null, "Dit samtykke"),
                        React.createElement("p", { dangerouslySetInnerHTML: { __html: datastore.data.marketingConsentIntoText } }),
                        React.createElement("div", { className: 'field', style: { paddingTop: '0px', display: 'flex', alignItems: 'center' }, onClick: handleConsentCheckBoxClicked },
                            React.createElement("div", { className: 'checkbox' }, isConsentChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#cfcfcf', strokeWidth: '2' },
                                    React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                    React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                            React.createElement("div", { className: 'checkboxLabel' }, datastore.data.marketingConsentText)),
                        React.createElement("h2", null, "Luk min profil"),
                        React.createElement("p", null, "N\u00E5r du lukker din profil vil du ikke l\u00E6ngere kunne g\u00F8re brug af Settles flytteplanner eller de ydelser/produkter og dertilh\u00F8rende rabatter."),
                        React.createElement("p", null, "L\u00E6s mere om hvordan vi behandler dine oplysninger i vores persondatapolitik."),
                        React.createElement("div", { className: 'fields' },
                            React.createElement("div", { className: 'field', style: { paddingTop: '0px', display: 'flex', alignItems: 'center' }, onClick: handleCheckBoxClicked },
                                React.createElement("div", { className: 'checkbox' }, isCloseAccountChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                    React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                    React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#cfcfcf', strokeWidth: '2' },
                                        React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                        React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                                React.createElement("div", { className: 'checkboxLabel' }, "Jeg \u00F8nsker at slette min profil"))),
                        React.createElement("h2", { className: 'PolicyLinksHeading' }, "L\u00E6s vores politikker"),
                        React.createElement("div", { className: 'PolicyLinksContainer' },
                            React.createElement("a", { href: 'https://settle.dk/persondata.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Persondatapolitik"),
                            React.createElement("a", { href: 'https://settle.dk/cookiepolitik.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Cookiepolitik"),
                            React.createElement("a", { href: 'https://settle.dk/handelsbetingelser.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Handelsbetingelser")),
                        React.createElement("div", { className: 'bottom' },
                            React.createElement("div", { className: 'buttons' },
                                React.createElement(Button, { onClick: handleSaveClicked, text: 'Gem' })))))))))));
};
export default EditDataOverlay;
