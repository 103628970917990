import React from 'react';
import './FooterMenu.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
const FooterMenu = ({ data, onChange }) => {
    const isFirstMenuItemSelected = () => {
        return data[0].isOpen;
    };
    const isLastMenuItemSelected = () => {
        return data[data.length - 1].isOpen;
    };
    const handleNextMenuItemClicked = () => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].isOpen) {
                if (i < data.length - 1) {
                    window.scrollTo(0, 0);
                    const rootMenuItemContainers = document.querySelectorAll('.rootMenu .itemContainer');
                    if (rootMenuItemContainers) {
                        const rootMenuItemContainerItem = rootMenuItemContainers[i + 1].firstChild;
                        if (rootMenuItemContainerItem) {
                            rootMenuItemContainerItem.click();
                        }
                    }
                    return;
                }
            }
        }
    };
    const handlePrevMenuItemClicked = () => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].isOpen) {
                if (i > 0) {
                    onChange(data[i - 1].id, true);
                    window.scrollTo(0, 0);
                    const rootMenuItemContainers = document.querySelectorAll('.rootMenu .itemContainer');
                    if (rootMenuItemContainers) {
                        const rootMenuItemContainerItem = rootMenuItemContainers[i - 1].firstChild;
                        if (rootMenuItemContainerItem) {
                            rootMenuItemContainerItem.click();
                        }
                    }
                    return;
                }
            }
        }
    };
    const getPrevMenuItemName = () => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].isOpen) {
                if (i > 0) {
                    return data[i - 1].title;
                }
            }
        }
        return '';
    };
    return (React.createElement(Container, { className: 'rootCollapsibleTimeLine footerMenuContainer', paddingTopReset: true, style: { paddingBottom: '0', position: 'relative' } },
        React.createElement(Row, { className: 'RootCallapsibleRow' },
            React.createElement(Column, { className: '', offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 6 } },
                React.createElement("div", { className: 'footerMenu' },
                    isFirstMenuItemSelected() ? (React.createElement("div", { className: 'footerMenuLeft' })) : (React.createElement("div", { className: 'footerMenuLeft', onClick: () => {
                            handlePrevMenuItemClicked();
                        } },
                        React.createElement("div", null,
                            React.createElement("svg", { width: '26', height: '26', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', style: { position: 'relative', top: '4px', right: '0px' } },
                                React.createElement("path", { d: 'm14.414 1.414-6 6-6-6', stroke: '#c4c5cb', transform: 'translate(18, 5) rotate(90)' }),
                                React.createElement("rect", { x: '1', y: '1', width: '25', height: '25', rx: '15', stroke: '#c4c5cb' }))),
                        React.createElement("div", null, getPrevMenuItemName()))),
                    isLastMenuItemSelected() ? (React.createElement("div", { className: 'footerMenuRight' })) : (React.createElement("div", { className: 'footerMenuRight', onClick: () => {
                            handleNextMenuItemClicked();
                        } },
                        React.createElement("div", null,
                            ' ',
                            "Du er nu klar til n\u00E6ste flyttefase -",
                            ' ',
                            React.createElement("b", null,
                                React.createElement("u", null, "G\u00E5 videre"))),
                        React.createElement("div", null,
                            React.createElement("svg", { width: '26', height: '26', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', style: { transform: 'rotate(-180deg)', position: 'relative', top: '4px', right: '0px' } },
                                React.createElement("path", { d: 'm14.414 1.414-6 6-6-6', stroke: '#c4c5cb', transform: 'translate(18, 5) rotate(90)' }),
                                React.createElement("rect", { x: '1', y: '1', width: '25', height: '25', rx: '15', stroke: '#c4c5cb' }))))))))));
};
export default FooterMenu;
