// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateAffiliatePartnerInfoGql = gql`
  mutation updateAffiliatePartner($input: UpdateAffiliatePartner!) {
    updateAffiliatePartner(input: $input) {
      id
    }
  }
`;

const AffiliatePartnerInfo = ({ data }) => {
  const toast = useToast();
  const [updateAffiliatePartnerInfo] = useMutation(updateAffiliatePartnerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (affiliatePartnerId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = affiliatePartnerId;
    updateAffiliatePartnerInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Partner
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              Navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.affiliatePartner.name}
              onBlur={(e) => updateData(data.affiliatePartner.id, "name", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              Kontaktperson:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.affiliatePartner.contactName}
              onBlur={(e) => updateData(data.affiliatePartner.id, "contactName", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              Telefon nr:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="phone"
              defaultValue={data.affiliatePartner.phone}
              onBlur={(e) => updateData(data.affiliatePartner.id, "phone", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              E-mail:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="email"
              defaultValue={data.affiliatePartner.email}
              onBlur={(e) => updateData(data.affiliatePartner.id, "email", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              E-mail finance:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="financialEmail"
              defaultValue={data.affiliatePartner.financialEmail}
              onBlur={(e) => updateData(data.affiliatePartner.id, "financialEmail", e.target.value)}
            />
          </Flex>


          
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              CVR:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="cvr"
              defaultValue={data.affiliatePartner.cvr}
              onBlur={(e) => updateData(data.affiliatePartner.id, "cvr", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
              Website:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="websiteUrl"
              defaultValue={data.affiliatePartner.websiteUrl}
              onBlur={(e) => updateData(data.affiliatePartner.id, "websiteUrl", e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default AffiliatePartnerInfo;
