import React from 'react';
import './button.scss';
const Button = (props) => {
    let buttonClass = 'button';
    if (props.grey) {
        if (props.grey) {
            buttonClass = 'buttonGrey';
        }
    }
    let maxWidth = '50%';
    if (props.fullwidth) {
        maxWidth = '100%';
    }
    if (!props.disabled) {
        props.disabled = false;
    }
    return (React.createElement(React.Fragment, null, props.disabled ? (React.createElement("div", { className: buttonClass, style: Object.assign({}, (props.width
            ? {
                width: props.width + 'px',
                maxWidth: maxWidth,
                filter: 'opacity(0.5)',
                cursor: 'initial',
            }
            : {
                width: '100%',
                maxWidth: maxWidth,
                filter: 'opacity(0.5)',
                cursor: 'initial',
            })) },
        React.createElement("div", { className: 'text' }, props.text))) : (React.createElement("div", { className: buttonClass, style: Object.assign({}, (props.width
            ? {
                width: props.width + 'px',
                maxWidth: maxWidth,
            }
            : {
                width: '100%',
                maxWidth: maxWidth,
            })), onClick: () => props.onClick() },
        React.createElement("div", { className: 'text' }, props.text)))));
};
export default Button;
